<template>
    <div class="container">
        <div class="m-3">
            <input name="userguid" type="hidden" v-bind:value=userguid>
            <h2 class="preference-header2">Seeking</h2>
            <div class="form-check form-switch" style="text-align: left">
                <input v-model="form.seekingmale" class="form-check-input" type="checkbox" role="switch"
                    id="men">
                <label class="form-check-label" for="men">Men</label>
            </div>
            <div class="form-check form-switch" style="text-align: left">
                <input v-model="form.seekingfemale" class="form-check-input" type="checkbox" role="switch"
                    id="woman">
                <label class="form-check-label" for="woman">Woman</label>
            </div>
            <div class="form-check form-switch" style="text-align: left">
                <input v-model="form.other" class="form-check-input" type="checkbox" role="switch"
                    id="other">
                <label class="form-check-label" for="other">Other</label>
            </div>
            <h2 class="preference-header2">Relationship Type</h2>
            <div class="form-check form-switch" style="text-align: left">
                <input v-model="form.ons" class="form-check-input" type="checkbox" role="switch" id="ons">
                <label class="form-check-label" for="ons">One-night-stand</label>
            </div>
            <div class="form-check form-switch" style="text-align: left">
                <input v-model="form.hookups" class="form-check-input" type="checkbox" role="switch"
                    id="hookups">
                <label class="form-check-label" for="hookups">Hookups</label>
            </div>
            <div class="form-check form-switch" style="text-align: left">
                <input v-model="form.dating" class="form-check-input" type="checkbox" role="switch"
                    id="dating">
                <label class="form-check-label" for="dating">Dating</label>
            </div>
            <div class="form-check form-switch" style="text-align: left">
                <input v-model="form.sugardating" class="form-check-input" type="checkbox" role="switch"
                    id="sugar-dating">
                <label class="form-check-label" for="sugar-dating">Sugar Dating</label>
            </div>
            <div class="form-check form-switch" style="text-align: left">
                <input v-model="form.friends" class="form-check-input" type="checkbox" role="switch"
                    id="friends">
                <label class="form-check-label" for="friends">Friends</label>
            </div>
            <div class="form-check form-switch" style="text-align: left">
                <input v-model="form.fwb" class="form-check-input" type="checkbox" role="switch" id="fwb">
                <label class="form-check-label" for="fwb">Friends with Benefits</label>
            </div>
            <div class="form-check form-switch" style="text-align: left">
                <input v-model="form.relationship" class="form-check-input" type="checkbox" role="switch"
                    id="relationship">
                <label class="form-check-label" for="relationship">Relationship</label>
            </div>
            <div class="input-section">
                <DoubleRangeSlider @input="getAgeFrom($event)" :value=this.user.agefrom :label="'Min Age'" :min=18 :max=99
                    :unit="'years'"></DoubleRangeSlider>
            </div>
            <div class="input-section">
                <DoubleRangeSlider @input="getAgeTo($event)" :value=this.user.ageto :label="'Max Age'" :min=18 :max=99
                    :unit="'years'">
                </DoubleRangeSlider>
            </div>
            <div class="input-section">
                <DoubleRangeSlider @input="getDistance($event)" :value=this.user.distance :label="'Distance'" :min=0
                    :max=200 :unit="'km'"></DoubleRangeSlider>
            </div>
            <div class="input-section">
                <button type="button" class="custom-button" v-on:click="search">Search</button>
            </div>
        </div>
    </div>
</template>
<script>
import DoubleRangeSlider from '../controls/DoubleRangeSlider.vue'
import { userguid, authenticationHeader, loggedin } from '@/utils/auth';
import { config } from '@/config';
import axios from 'axios';

export default {
    name: 'PreferencesVue',
    components: {
        DoubleRangeSlider,
    },
    props: ['user'],
    computed: {
        userguid: userguid,
    },
    data() {
        return {
            form: {},
            userlocal: this.user,
            locale: {}
        }
    },
    methods: {
        async getData() {
            this.form.ons = this.userlocal.ons === 1 ? true : false
            this.form.hookups = this.user.hookups === 1 ? true : false
            this.form.dating = this.user.dating === 1 ? true : false
            this.form.sugardating = this.user.sugardating === 1 ? true : false
            this.form.friends = this.user.friends === 1 ? true : false
            this.form.fwb = this.user.fwb === 1 ? true : false
            this.form.user_looking_for = this.user.user_looking_for === 1 ? true : false
            this.form.relationship = this.user.relationship === 1 ? true : false
            this.form.seekingmale = this.user.seekingmale === 1 ? true : false
            this.form.seekingfemale = this.user.seekingfemale === 1 ? true : false
            this.form.seekingother = this.user.seekingother === 1 ? true : false
            this.form.distance = this.user.distance
            this.form.agefrom = this.user.agefrom
            this.form.ageto = this.user.ageto
            this.locale.latitude = this.user.latitude
            this.locale.longatude = this.user.longatude
        },
        async postData() {
            this.form.userguid = this.userguid;
            axios.put(config.APP_API + '/user/preferences',
                {
                    ons: this.form.ons == 1 ? true : false,
                    hookups: this.form.hookups == 1 ? true : false,
                    dating: this.form.dating == 1 ? true : false,
                    sugardating: this.form.sugardating == 1 ? true : false,
                    friends: this.form.friends == 1 ? true : false,
                    fwb: this.form.fwb == 1 ? true : false,
                    user_looking_for: this.form.user_looking_for === null ? '' : this.form.user_looking_for,
                    relationship: this.form.relationship == 1 ? true : false,
                    seekingmale: this.form.seekingmale == 1 ? true : false,
                    seekingfemale: this.form.seekingfemale == 1 ? true : false,
                    seekingother: this.form.seekingother == 1 ? true : false,
                    distance: this.form.distance,
                    latitude: this.locale.latitude,
                    longatude: this.locale.longatude,
                    agefrom: this.form.agefrom,
                    ageto: this.form.ageto,
                    userguid: this.userguid
                },
                authenticationHeader
            ).then((response) => {
                // Handle success
                console.log(response)
            }).catch((error) => {
                // Handle error
                console.log(error)
            });
        },
        getDistance(distance) {
            this.form.distance = distance
        },
        getAgeFrom(agefrom) {
            this.form.agefrom = agefrom
        },
        getAgeTo(ageto) {
            this.form.ageto = ageto
        },
        search() {
            this.postData()
            this.$router.push("/members");
        }
    },
    mounted() {
        if (!loggedin()) {
            this.$router.push({ name: 'Home' });
        }
        else {
            this.getData();
        }

    }

}
</script>
<style>
.preference-header2 {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
}

hr {
    margin-top: 20px;
    margin-bottom: 20px;
}

.input-section {
    margin-top: 40px;
}

.form-check-input:checked {
  background-color: #000 !important;
  border-color: #000 !important;
}

</style>