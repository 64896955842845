import { createWebHistory, createRouter } from "vue-router";
import HomePage from "./components/HomePage.vue";
import Chat from "./components/messages/Chat.vue";
import CheckEmail from "./components/CheckEmail.vue";
import contentPage from "./components/content/contentPage.vue";
import LoginEmail from "./components/login/LoginEmail.vue";
import LoginLink from "./components/login/LoginLink.vue";
import Likes from "./components/likes/Likes.vue";
import ForgotPassword from "./components/login/ForgotPassword.vue";
import ResetPassword from "./components/login/ResetPassword.vue";
import Register from "./components/Register.vue";
/*import SignUp from "./components/signup/SignUp.vue";*/
import SignUp from "./components/signup/SignUpMembers.vue";
import Profile from "./components/profile/Profile.vue";
import Matches from "./components/connections/Matches.vue";
import MatchedProfile from "./components/connections/MatchedProfiles.vue";
import Messages from "./components/messages/Messages.vue";
import Members from "./components/Members.vue";
import NoMoreMembers from "./components/NoMoreMembers.vue";
import Settings from "./components/settings/Settings.vue";
import facebook from "./components/login/FBLogin.vue";
import NotFound from "./components/errors/NotFound.vue";
import VideoUpload from "./components/video/VideoUpload.vue";
import MemberView from './components/member/MemberView.vue';
import FailureVue from "./components/payment/FailurePayment.vue";
import SuccessVue from "./components/payment/SuccessPayment.vue";
import Payment from "./components/payment/Payment.vue";
/*import SearchFor from "./components/search/SearchFor.vue";*/
import StripeSubscription from "./components/payment/StripeSubscription.vue";
import SuperLikes from "./components/payment/SuperLikes.vue"

import Events from "./components/events/Events.vue";
import MemberEvents from "./components/events/MemberEvents.vue";
import EventCreate from "./components/events/EventCreate.vue";
import EventList from "./components/events/EventList.vue";

import GroupCreate from "./components/groups/GroupCreate.vue";

const routes = [
  {
    name: "Events",
    path: "/events",
    component: Events,
  },
  {
    name: "EventCreate",
    path: "/events/create",
    component: EventCreate,
  },
  {
    name: "EventList",
    path: "/events/list",
    component: EventList,
  },
  {
    name: "MemberEvents",
    path: "/myevents",
    component: MemberEvents,
  },
  {
    name: "GroupCreate",
    path: "/groups/create",
    component: GroupCreate,
  },
  {
    name: "Facebook",
    path: "/facebook",
    component: facebook,
  },
  {
    name: "HomePage",
    path: "/home",
    component: HomePage,
  },
  {
    name: "HomePageRoot",
    path: "/",
    component: HomePage,
  },
  {
    name: "Connections",
    path: "/connections",
    component: Matches,
  },
  {
    name: "SignUpSeeking",
    path: "/signup/seeking",
    component: HomePage,
  },
  {
    name: "SignUpLocation",
    path: "/signup/location",
    component: SignUp,
  },
  {
    name: "SignUpDOB",
    path: "/signup/dob",
    component: SignUp,
  },
  {
    name: "SignUpSeekingAge",
    path: "/signup/seekingage",
    component: SignUp,
  },
  {
    name: "SignUpEmail",
    path: "/signup/email",
    component: SignUp,
  },
  {
    name: "ProfileImage",
    path: "/signup/profileimage",
    component: Profile
  },
  {
    name: "CheckEmail",
    path: "/checkemail",
    component: CheckEmail,
  },
  {
    name: "Chat",
    path: "/chat/:member",
    component: Chat,
  },
  {
    name: "Content",
    path: "/content/:ref",
    component: contentPage,
  },
  {
    name: "FailureVue",
    path: "/failure",
    component: FailureVue
  },
  {
    name: "Payment",
    path: "/payment",
    component: Payment
  },
  {
    name: "Subscription",
    path: "/subscription",
    component: StripeSubscription
  },
  {
    path: "/success",
    name: "SuccessVue",
    component: SuccessVue
  },
  {
    name: "Login",
    path: "/login",
    component: LoginEmail,
  },
  {
    name: "LoginLink",
    path: "/login/link/:logintoken",
    component: LoginLink,
  },
  {
    name: "logout",
    path: "/logout",
    component: HomePage,
  },
  {
    name: "Likes",
    path: "/likes",
    component: Likes,
  },
  {
    name: "ForgotPassword",
    path: "/forgotpassword",
    component: ForgotPassword,
  },
  {
    name: "ResetPassword",
    path: "/resetpassword",
    component: ResetPassword,
  },
  {
    name: "RegisterNew",
    path: "/register",
    component: Register,
  },
  {
    name: "SignUp",
    path: "/signup",
    component: SignUp,
  },
  {
    name: "SignUpAuthenticationType",
    path: "/home/sign-in-authentication-type",
    component: HomePage,
  },
  {
    name: "JoinNow",
    path: "/home",
    component: HomePage,
  },
  {
    name: "Profile",
    path: "/profile",
    component: Profile,
  },
  {
    name: "Profile About Me",
    path: "/profile-about-me",
    component: Profile,
  },
  {
    name: "Matches",
    path: "/matches",
    component: Matches,
  },
  {
    name: "Messages",
    path: "/messages",
    component: Messages,
  },
  {
    name: "MatchedProfile",
    path: "/matchedprofiles",
    component: MatchedProfile,
  },
  {
    name: "Members",
    path: "/members",
    component: Members,
  },
  {
    name: "NoMoreMembers",
    path: "/nomoremembers",
    component: NoMoreMembers,
  },
  {
    name: "MembersGuid",
    path: "/members/:id",
    component: Members,
  },
  {
    name: "MemberView",
    path: "/member/:id",
    component: MemberView,
  },
  {
    name: "MyLocation",
    path: "/profile/my-location",
    component: Profile,
  },
  {
    name: "Settings",
    path: '/settings',
    component: Settings,
  },
  {
    name: "Search",
    path: "/profile/preferences",
    component: Profile
  },
  {
    name: "SuperLikes",
    path: "/payment/superlikes",
    component: SuperLikes,
  },
  {
    name: "Preferences",
    path: "/profile/preferences",
    component: Profile,
  },
  {
    name: "VideoUpload",
    path: "/videoupload",
    component: VideoUpload,
  },
  {
    path: "/404",
    name: "NotFound",
    component: NotFound,
  },
  {
    path: "/:catchAll(.*)",
    redirect: "404",
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
