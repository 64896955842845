<template>
    <div class="content">
        <div class="card">
            <div class="user">
                <img class="user" :src=returnImageString alt="" />
                <div class="profile">
                    <div class="name">{{ member.fullname }} <span>{{ age }}</span></div>
                    <div class="local">
                        <i class="fas fa-map-marker-alt"></i>
                        <span>{{ member.location }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="buttons">
            <div class="no" @click="reject()">
                <i class="fas fa-times"></i>
            </div>
            <div class="star" @click="superlike()">
                <i class="fas fa-star fa"></i>
            </div>
            <div class="heart" @click="like()">
                <i class="fas fa-heart"></i>
            </div>
            <div class="heart" @click="review()">
                <i class="fas fa-magnifying-glass"></i>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import { userguid, authenticationHeader } from '../../utils/auth';
import { config } from '@/config';

export default {
    name: "SwipeCardVue",
    current: true,
    components: {
    },
    data() {
        return {
            targetuserguid: {},
            member: {},
        }
    },

    computed: {
        returnImageString() {

            // return 'url(' + config.APP_API + '/images/' + this.targetuserguid + '/1)';
            if (typeof this.targetuserguid === 'string'){
            return config.IMAGE_API + '/images/user/' + this.targetuserguid + '/index/1';
            }
            else {
                return config.IMAGE_API + '/images/user/' + this.userguid + '/index/1';
            }

        },
        userguid: userguid,
    },
    mounted() {

        this.getNext();
    },
    methods: {
        async like() {
            console.log('Like clicked');
            axios.post(config.APP_API + '/swipe/right',
                {
                    userguid: this.userguid,
                    targetuserguid: this.targetuserguid
                },
                authenticationHeader()).then((response) => {
                    // Handle success
                    console.log(response)
                    if (typeof this.$route.params.id !== 'undefined') {
                        this.$route.params.id = null;
                    }
                    // Get next memeber
                    this.getNext();
                })
        },
        async reject() {
            axios.post(config.APP_API + '/swipe/left',
                {
                    userguid: this.userguid,
                    targetuserguid: this.targetuserguid
                },
                authenticationHeader()).then((response) => {
                    // Handle success
                    console.log(response)
                    if (typeof this.$route.params.id !== 'undefined') {
                        this.$route.params.id = null;
                    }
                    // Get next memeber
                    this.getNext();
                });
        },
        async superlike() {
            axios.post(config.APP_API + '/swipe/superlike',
                {
                    userguid: this.userguid,
                    targetuserguid: this.targetuserguid
                },
                authenticationHeader()).then((response) => {
                    console.log(response)
                    if (typeof this.$route.params.id !== 'undefined') {
                        this.$route.params.id = null;
                    }
                    this.getNext();
                }).catch((error) => { 
                    console.log('Super Like Error', error.response.status, error.response.data)
                    if (error.response.status == 501){
                        // User needs tokens
                        this.$router.push('/payment/superlikes');
                    }
                })
        },
        review() {
            this.$router.push('/member/' + this.targetuserguid);
        },
        async getNext() {
            // If the user guid has been provided then get the specific user
            //TODO Add a check that the specified user is in the matched users
            if (this.$route.params.id) {
                console.log('Getting specific user', this.$route.params.id)
                axios.get(config.APP_API + '/member/' + this.$route.params.id, authenticationHeader())
                    .then((response) => {
                        this.member = response.data;
                        this.targetuserguid = this.$route.params.id;
                    }).catch((error) => {
                        console.log(error)
                    }
                    )
            }
            else {
                // Else get the next user
                axios.get(config.APP_API + '/search/next/' + userguid(),
                    authenticationHeader()).then((response) => {
                        // Get next memeber
                        this.member = response.data;
                        this.targetuserguid = this.member.targetuserguid;
                    }).catch((error) => {
                        if (error.response.status == 404){
                            this.$router.push({ name: 'NoMoreMatches' });
                        }
                    });
            }
        }
    },
    watch: {
    }
}			
</script>
<style scoped>
.container {
    display: flex;
}

.content {
    width: 100%;
    display: flex;
    padding: 20px 0;
    align-items: center;
    flex-direction: column;
}

.card {
    position: relative;
    height: 450px;
    width: 380px;
}

.content .card .user {
    width: 380px;
    height: 450px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 1px 10px 0 rgba(0, 20, 20, 0.5);
}

.content .card .user .profile {
    position: absolute;
    bottom: 0px;
    left: 20px;
}

.content .card .user .name {
    color: #fff;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0.5px;
    text-shadow: 1px 1px 5px #444;
}

.content .card .user .name span {
    font-size: 26px;
}

.content .card .local {
    display: flex;
    align-items: center;
    padding: 0 0 20px 0;
}

.content .card .local i {
    margin: 5px 0;
    color: #fff;
}

.content .card .local span {
    color: #fff;
    padding: 0 10px;
    text-shadow: 1px 1px 5px #444;
}

.content .buttons {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.content .buttons .no i {
    color: #fd2e79;
    font-size: 33px;
}

.content .buttons .star i {
    color: #2bb1ff;
    font-size: 22px;
}

.content .buttons .heart i {
    color: #11e298;
    font-size: 32px;
}

.content .buttons .no,
.content .buttons .star,
.content .buttons .heart {
    background-color: #fff;
    width: 70px;
    height: 70px;
    box-shadow: 0 1px 10px 0 rgba(0, 20, 20, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 70px;
    margin: 4px;
    transition: 0.2s transform;
}

.content .buttons .no:hover,
.content .buttons .star:hover,
.content .buttons .heart:hover {
    transform: scale(1.1);
}

.content .buttons .star {
    width: 60px;
    height: 60px;
}
</style>
