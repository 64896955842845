<template>
    <div v-if="display" class="img">
        <img :src="config.IMAGE_API + '/images/file/' + data.image" alt="" @click="profileImageSelected()">
        <div @click="removeImage()"><i class="fas fa-trash"></i></div>
    </div>
</template>
<script>
import { config } from '../../config'
import { getState } from '@/utils/globalFunctions'
import axios from 'axios'

export default {
    name: "MyImagePanelThumbNail",
    props: ['image', 'data'],
    data() {
        return {
            display: true,
            config: config
        }
    },
    mounted() {

    },
    methods: {
        profileImageSelected() {
            if (getState() === config.RESTRICTED) {
                console.log('restricted')
                return
            }
            // Update the users main profile image
            axios.put(config.IMAGE_API + "/setprofileimage/" + this.data.guid + "/image/" + this.data.image, {}).then((response) => {
                console.log(response)
                const profileImageSelected = new CustomEvent(config.events.USER_PROFILE_IMAGE_SELECTED, {
                    detail: {
                        data: this.data
                    }
                })
                window.dispatchEvent(profileImageSelected)
            }).catch((error) => {
                console.log(error)
            })
        },
        removeImage() {
            console.log('remove image: ' + JSON.stringify(this.data))
            axios.delete(config.IMAGE_API + '/images/user/' + this.data.guid + '/image/' + this.data.image)
                .then((response) => {
                    console.log(response)
                    const userImageDeleted = new CustomEvent(config.events.USER_PROFILE_IMAGE_DELETED, { detail: { image: this.data } });
                    window.dispatchEvent(userImageDeleted);
                    this.display = false
                })
                .catch((error) => {
                    console.log(error)
                }
                )
        }
    }
}
</script>
<style scoped>
.img div {
    color: #fff;
    position: absolute;
    bottom: 10px;
    right: 10px;
    cursor: pointer;
    background-image: linear-gradient(166deg, rgb(211, 148, 0) 0%, rgb(211, 148, 0) 100%);
    font-size: 20px;
    width: 35px;
    height: 35px;
    display: inline-block;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
    padding-top: 6px;
}
</style>