<template>
    <section class="log-reg">
        <div class="col">
            <div class="row justify-content-center">
                <div style="margin-top:100px;" class="content-inner">
                    <h1>{{ page.title }}</h1>
                    <div class="content" v-html="page.content">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
  
<script>
import axios from 'axios'
import { config } from '../../config.js'
import { ref } from 'vue'

export default {
    name: 'contentPage',
    setup() {
        const page = ref('')
        axios
            .get(config.APP_API + '/content/ref/' + window.location.pathname.split('/')[2])
            .then((response) => {
                page.value = response.data;
            })
        return {
            page
        }
    }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
    margin: 40px 0 0;
    font-size: 2.5rem;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}

.content > p {
  margin: 1rem !important;
}

.content-inner {
    width:80%;
}
</style>
  