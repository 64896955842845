<template>
    <!-- Sender Message-->
    <div class="media w-75 ml-auto mb-3">
        <div class="media-body">
            <div class="w-100 bg-primary rounded py-2 px-3 mb-2">
                <p class="text-small mb-0 text-white">{{ message.message }}</p>
            </div>
            <p class="small text-muted">{{ datetime }}</p>
        </div>
    </div>
</template>
<script>
import { config } from '@/config';

export default {
    name: "MessageSender",
    props: [
        'datetime',
        'message',
    ],
    computed: {
        fromuser() {
            return this.$route.query.fromuser
        },
        config() {
            return config
        }
    },
}
</script>
<style></style>