<template>
    <div class="container" style="margin-top:100px;">
        <div class="row rounded-lg overflow-hidden shadow">
            <!-- Users box-->
            <div class="px-0">
                <div class="bg-white">
                    <div class="bg-gray px-4 py-2 bg-light">
                        <p class="h5 mb-0 py-1">Connections</p>
                    </div>
                    <div class="messages-box">
                        <div class="list-group rounded-0">
                            <span v-for="match in matches" :key="match.guid">
                                <a v-bind:href="'/chat/' + match.guid"
                                    class="list-group-item list-group-item-action list-group-item-light rounded-0">
                                    <ProfileMini :fromuser="match.guid" @click="openChat()"></ProfileMini>
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import ProfileMini from '../profile/ProfileMini.vue';
import { loggedin, userguid, authenticationHeader } from '@/utils/auth';
import { config } from '@/config';

export default {
    name: 'MatchesVue',
    components: {
        ProfileMini
    },
    data() {
        return {
            userguid: userguid(),
            matches: []
        }
    },
    methods: {
        async getData() {
            axios.get(config.APP_API + '/matches/' + this.userguid, authenticationHeader())
                .then((response) => {
                    const matches = response.data;
                    this.matches = matches
                    console.log(response.data)
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        openChat(member) {
            this.$router.push({ name: 'Chat', params: { member: member.guid } })
        },
    },
    mounted() {
        if (!loggedin()) {
            this.$router.push('/home');
        } else {
            this.getData();
        }
    }
}
</script>
<style></style>