<template>
  <div v-if="showToast" class="toast-container position-absolute p-3 top-0 start-50 translate-middle-x" ref="toast">
    <div :class="{ toast: true, show: showToast }">
      <div class="toast-header">
        <strong class="me-auto">{{ data.title }}</strong>
      </div>
      <div class="toast-body">
        <p>{{ data.message }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ToastVue',
  props: ['data'],
  data() {
    return { showToast: false }
  },
  methods: {
    show() {
      this.showToast = true;
      setTimeout(() => { this.hide() }, 3000);
    },
    hide() {
      this.showToast = false;
    },
  },
  mounted() {

  },
}
</script>
<style scoped></style>