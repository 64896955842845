<template>
    <div class="profile-meta-box">
        <ul class="p-m-b">
            <li>
                <a @click.prevent="$router.push({name: 'Profile'})" :style="iconButtonStyle()">
                    <i class="bi bi-camera"></i>
                </a>
            </li>
            <li>
                <a @click.prevent="$router.push({name: 'Profile About Me'})" :style="iconButtonStyle()">
                    <i class="bi bi-pencil"></i>
                </a>
            </li>
            <li>
                <a @click.prevent="$router.push({name: 'MyLocation'})" :style="iconButtonStyle()">
                    <i class="bi bi-geo-alt"></i>
                </a>
            </li>
            <li>
                <a @click.prevent="$router.push({name: 'Preferences'})" :style="iconButtonStyle()">
                    <i class="bi bi-sliders"></i>
                </a>
            </li>
            <li v-if="config.FEATURES.Upgrades">
                <a @click.prevent="$router.push({name: 'Payment'})" :style="iconButtonStyle()">
                    <i class="bi bi-gem"></i>
                </a>
            </li>
        </ul>
    </div>
</template>
<script>
import { toggleState, getState } from '../../utils/globalFunctions'
import { config } from '../../config'

export default {
    name: 'ProfileItemsMenu',
    data() {
        return {
            data: {
                state: getState(),
            },
            config: config,
        }
    },
    methods: {
        toggleState() {
            toggleState();
        },
        iconButtonStyle(){
            if (this.data.state === config.PUBLIC){
                return { background: '#fff' }
            } else if (this.data.state === config.RESTRICTED){
                return { background: 'black' }
            }
        }
    },
    mounted() {
        window.addEventListener(config.events.USER_STATE_CHANGE, (event) => {
            this.data.state = event.detail.state;
        });
    },
}
</script>
<style scoped>


</style>