<template>
  <div>
    <h4>Please Try Again</h4>
  </div>
</template>

<script>
export default {
  name: "Failure-Vue",
  setup() {
    return {};
  }
};
</script>

<style lang="scss" scoped></style>
