import { config } from "@/config";

export const chatDateTime = (created) => {
  let result = "";
  const date = new Date(created * 1000);
  let month = date.getMonth();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  month = months[month];
  const hours = date.getHours();
  const ampm = hours >= 12 ? "pm" : "am";
  const minutes = date.getMinutes();
  let day = date.getDate();
  if (day < 10) day = "0" + day;
  result = 
    month +
    " " +
    day +
    " " +
    hours +
    ":" +
    minutes.toString().padStart(2, "0") +
    " " +
    ampm;
    return result;
};

export const isMobile = () => {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return true;
  } else {
    return false;
  }
};

export const notIsMobile = () => {
  return !isMobile();
};

export const findObject = (obj = {}, key, value) => {
  const result = [];
  const recursiveSearch = (obj = {}) => {
    if (!obj || typeof obj !== "object") {
      return;
    }
    if (obj[key] === value) {
      result.push(obj);
    }
    Object.keys(obj).forEach(function (k) {
      recursiveSearch(obj[k]);
    });
  };
  recursiveSearch(obj);
  return result;
};

export const toggleState = () => {
  const localConfig = config;
  var state = localStorage.getItem("state");
  if (state === "undefined" || state === null) {
    localStorage.setItem("state", localConfig.RESTRICTED);
    state = localConfig.RESTRICTED;
  }

  state =
    state === localConfig.PUBLIC ? localConfig.RESTRICTED : localConfig.PUBLIC;
  localStorage.setItem("state", state);
  window.dispatchEvent(
    new CustomEvent(localConfig.events.USER_STATE_CHANGE, {
      detail: { state },
    })
  );
  return state;
};

export const getState = () => {
  let state = localStorage.getItem("state");
  if (!config.FEATURES.NaughtyMode) {
    state = config.PUBLIC;
  }
  return state;
};

export const setState = (state) => {
  localStorage.setItem("state", state);
  window.dispatchEvent(
    new CustomEvent(config.events.USER_STATE_CHANGE, { detail: { state } })
  );
};

export const restrictedState = () => {
  return getState() === config.RESTRICTED;
};


