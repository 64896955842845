<template>
    <img src="https://i.etsystatic.com/30360802/r/il/924454/3362615769/il_794xN.3362615769_tr3b.jpg"/>
</template>
<script>
export default {
    name: 'MatchedProfilesVue',
    components: {

    },
}
</script>
<style>
</style>