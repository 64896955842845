<template>
  <section class="profile-section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-5 col-lg-6 col-md-8">
          <div class="left-profile-area">
            <ProfileItemsMenu></ProfileItemsMenu>
            <div v-show="currentRouteName() === 'Profile'" class="profile-about-box">
              <div class="p-inner-content">
                <div v-show="currentRouteName() === 'Profile'" class="profile-img">
                  <img
                    :src="config.IMAGE_API + '/images/user/' + userguid + '/index/1/?=' + new Date()" ref="profileimage">
                </div>
                <h5 v-show="currentRouteName() === 'Profile'" class="name">
                  {{ user.fullname }}
                </h5>
                <ImageUploaderVue v-show="currentRouteName() === 'Profile'"
                  @imageCropped="$emit('imageSubmitted', $event)" :state="config.PUBLIC">
                </ImageUploaderVue>
              </div>
            </div>
            <div v-show="currentRouteName() === 'Profile'" class="profile-uplodate-photo">
              <MyImagePanel :key="user" :guid="userguid" :state="config.PUBLIC"></MyImagePanel>
            </div>
            <div v-show="currentRouteName() === 'Preferences' || currentRouteName() === 'Search'" class="preferences">
              <Preferences :key="user" :user="user"></Preferences>
            </div>
            <div v-show="currentRouteName() === 'Profile About Me'" class="preferences">
              <ProfileAboutMe :key="user" :user="user"></ProfileAboutMe>
            </div>
            <div v-show="currentRouteName() === 'MyLocation'">
              <MyLocation :key="user" :user="user"></MyLocation>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import MyImagePanel from './MyImagesPanel.vue'
import ImageUploaderVue from '../images/ImageUploader.vue'
import ProfileItemsMenu from './ProfileItemsMenu.vue'
import Preferences from './Preferences.vue'
import ProfileAboutMe from './ProfileAboutMe.vue'
import MyLocation from './MyLocation.vue'
import { userguid, authenticationHeader } from '../../utils/auth'
import { config } from '../../config'
import axios from 'axios'

export default {
  name: "ProfileVue",
  components: {
    MyImagePanel,
    ImageUploaderVue,
    ProfileItemsMenu,
    Preferences,
    ProfileAboutMe,
    MyLocation,
  },
  data() {
    return {
      config: config,
      user: {},
    }
  },
  setup() {

    return {
      userguid: userguid(),
    }
  },
  methods: {
    currentRouteName() {
    console.log('currentRouteName', this.$route.name)
      return this.$route.name;
    },
  },
  created() {
    axios.get(config.APP_API + '/user/profile/' + this.userguid,
      authenticationHeader())
      .then((response) => {
        this.user = response.data[0];
      })
      .catch((error) => {
        console.log(error)
      })
    // Add listener for profile image changes
    window.addEventListener(config.events.USER_PROFILE_IMAGE_SELECTED, (events) => {
      console.log('Profile image changed', events.detail)
      this.$refs.profileimage.src = config.IMAGE_API + '/images/user/' + this.userguid + '/index/1/?=' + new Date()
    })
  },
}
</script>
<style scoped>
.profile-section {
  padding: 95px 0px 120px;
}

.profile-section .left-profile-area .profile-uplodate-photo {
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  -webkit-box-shadow: 0px 10px 5px 0px rgba(119, 123, 146, 0.02);
  box-shadow: 0px 10px 5px 0px rgba(119, 123, 146, 0.01);
  padding: 0;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.profile-section .left-profile-area .profile-about-box .p-inner-content .profile-img {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 9;
}

.profile-section .left-profile-area .profile-about-box .p-inner-content .profile-img::before {
  position: absolute;
  content: "";
  background: rgba(255, 255, 255, 0.2);
  z-index: -1;
  left: -10px;
}

.profile-section .left-profile-area .profile-about-box .p-inner-content .profile-img::after {
  content: "";
  background: rgba(255, 255, 255, 0.2);
  left: -20px;
}

.profile-section .left-profile-area .profile-about-box .p-inner-content .profile-img {
  background: #fff;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
  position: relative;
}

.profile-section .left-profile-area .profile-about-box .p-inner-content {
  position: relative;
  text-align: center;
}

.profile-uplodate-photo {
  text-align: center;
}

.profile-section .left-profile-area .profile-about-box .p-inner-content .profile-img img {
  border-radius: 50%;
  width:200px;
}

.profile-section .left-profile-area .profile-about-box .p-inner-content {
  position: relative;
  padding: 0 20px 20px;
  text-align: center;
}




</style>