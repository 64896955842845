<template>
    <section v-if="validtoken()" class="log-reg mt-3">
        <div class="container">
            <div class="row justify-content-center">
                <div class="image image-log">
                </div>
                <div class="col-lg-7">
                    <div class="log-reg-inner">
                        <div class="main-content inloginp">
                            <div class="form-group">
                                <label for="">Password*</label>
                                <input v-model="data.password" type="text" class="my-form-control"
                                    placeholder="Enter Your Password">
                            </div>
                            <div class="form-group">
                                <label for="">Confirm Password*</label>
                                <input v-model="data.confirmpassword" type="text" class="my-form-control"
                                    placeholder="Enter Your Password">
                            </div>
                            <div class="button-wrapper">
                                <button @click="submit()" type="submit" class="custom-button">Reset Password</button>
                            </div>
                            <Toast :data="data"></Toast>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section v-if="!validtoken()" class="log-reg mt-3">
        <div class="container">
            <div class="row justify-content-center">
                <div class="image image-log">
                </div>
                <div class="col-lg-7">
                    <div class="log-reg-inner">
                        <div class="main-content inloginp">
                            <div class="button-wrapper">
                                <button @click="gotoLogin()" type="submit" class="custom-button">Go to Login page</button>
                            </div>
                            <Toast :data="data"></Toast>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import Toast from '../controls/toast.vue'
import axios from 'axios'
import { config } from '@/config'


export default {
    name: "ResetPasswordVue",
    components: {
        Toast
    },
    data() {
        return {
            data: {
                password: '',
                confirmpassword: '',
                title: '',
                message: '',
                showToast: false,
            }
        }
    },
    methods: {
        validateForm() {
            return (this.data.password.length !== 0 && this.data.password === this.data.confirmpassword);
        },
        hideToast() {
            this.data.showToast = false;
        },
        gotoLogin() {
            console.log(this.$route);
            this.$routers.push('/login')
        },
        async validtoken() {
              const token = {
                token: this.$route.query.token,
                userguid: this.$route.query.user,
            }
            console.log(token)
            await axios.post(config.APP_API + '/login/validateaccesstoken',
                token
            ).then((response) => {
                console.log(response.data)
                return true;
            }
            ).catch((err) => {
                console.log(err);
                return false;
            })
        },
        async submit() {
            if (this.validateForm()) {
                await axios.post(config.APP_API + '/login/resetpassword', {
                    password: this.data.password,
                    token: this.data.token,
                    userguid: this.data.userguid
                }).then((response) => {
                    console.log(response.json())
                }).catch((error) => {
                    console.error(error.status);
                })
            } else {
                if (this.data.showToast) {
                    this.data.showToast = false;
                } else {
                    this.data.title = 'Forgot Password';
                    this.data.message = 'Enter your email address';
                    this.data.showToast = true;
                }
            }
        }
    },
}
</script>
<style scoped>
.log-reg {
    top: 100px;
}
</style> 