<template>
    <div class="container">
        <div class="s-input mr-3 mt-3">
            <button type="button" class="custom-button fixed-width" v-on:click="todaySearch">Today</button>
        </div>
        <div class="s-input mr-3 mt-3">
            <button type="button" class="custom-button fixed-width" v-on:click="tomorrowSearch">Tomorrow</button>
        </div>
        <div class="s-input mr-3 mt-3">
            <button type="button" class="custom-button fixed-width" v-on:click="nextweekendSearch">Next weekend</button>
        </div>
        <div class="s-input mr-3 mt-3">
            <button type="button" class="custom-button fixed-width" v-on:click="choosedateSearch">Choose date</button>
        </div>
        <div class="s-input mr-3 mt-3">
            <button type="button" class="custom-button fixed-width" v-on:click="upcomingSearch">All upcoming</button>
        </div>
        <div class="s-input mr-3 mt-3">
            <button type="button" class="custom-button fixed-width" v-on:click="createEvent">Create an event</button>
        </div>
        <div v-show="currentRouteName() === 'MemberEvents'" class="preferences">
            <MemberEvents :key="user" :user="user"></MemberEvents>
        </div>
        <div v-show="currentRouteName() === 'CreateEvent'" class="preferences">
            <EventCreate :key="user" :user="user"></EventCreate>
        </div>
    </div>
</template>
<script>
import MemberEvents from '../events/MemberEvents.vue'
import EventCreate from '../events/EventCreate.vue'
import { userguid, authenticationHeader, loggedin } from '@/utils/auth';
import { config } from '@/config';
import axios from 'axios';

export default {
    name: 'EventsVue',
    components: {
        MemberEvents,
        EventCreate
    },
  data() {
    return {
      config: config,
      user: {},
    }
  },
  setup() {

    return {
      userguid: userguid(),
    }
  },
  methods: {
    currentRouteName() {
      return this.$route.name;
    },
    getData(){

    },
    todaySearch() {
        this.$router.push("/events/today");
    },
    tomorrowSearch() {
        this.$router.push("/events/tomorrow");
    },  
    nextweekendSearch() {
        this.$router.push("/events/nextweekend");
    },
    choosedateSearch() {
        this.$router.push("/events/choosedate");
    },
    upcomingSearch() {
        this.$router.push("/events/upcoming");
    },
    createEvent() {
        this.$router.push("/events/create");
    },
  },
  created() {
    axios.get(config.APP_API + '/user/profile/' + this.userguid,
      authenticationHeader())
      .then((response) => {
        this.user = response.data[0];
      })
      .catch((error) => {
        console.log(error)
      })
  },
    mounted() {
        if (!loggedin()) {
            this.$router.push({ name: 'Home' });
        }
        else {
            this.getData();
        }

    }

}
</script>
<style>
.preference-header2 {
    margin-top: 20px;
    margin-bottom: 20px;
}

hr {
    margin-top: 20px;
    margin-bottom: 20px;
}
</style>