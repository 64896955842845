<template>
    <div class="join-now-box wow fadeInUp">
        <div class="single-option last">
            <p class="title">
                Country
            </p>
            <div class="option ml-3">
                <select name="country" ref="country" class="select-bar">
                    <option>Select Country</option>
                    <option value="AU">Australia</option>
                </select>
            </div>
        </div>
        <div class="joun-button mt-4" style="float: left;margin: 8%;">
            <button class="custom-button" @click="back()">Back</button>
        </div>
        <div class="joun-button mt-4">
            <button class="custom-button" @click="joinNow()">Next</button>
        </div>
    </div>
    <Toast :data="data" ref="toast"></Toast>
</template>
<script>
import Toast from '../controls/toast.vue'
import { config } from '@/config';

export default {
    components: {
        Toast,
    },
    props: ['target'],
    data() {
        return {
            data: {
                post: {},
            },

        }
    },
    computed: {
        config() {
            return config;
        },
    },
    methods: {
        validform() {
            if (this.$refs.country.value === 'Select Country') {
                return false;
            } else {
                return true;
            }
        },
        back() {
            this.$router.push('/signup/seeking');
        },
        async joinNow() {
            if (this.validform()) {
                this.data.post.country = this.$refs.country.value;
                let registration = JSON.parse(localStorage.getItem("registration"))
                registration.country = this.data.post.country;
                localStorage.setItem("registration", JSON.stringify(registration))
                this.$router.push('/signup/dob');
            } else {
                this.data.title = 'User information'
                this.data.message = 'Please fill in all of the information to complete your registration'
                this.data.showToast = true
                this.$refs.toast.show();
                return false;
            }
        },
    },
}
</script>
<style scoped>
input,
button,
select {
    height: 40px;
}

.select {
    padding-top: 6px;
}

.title {
    position: relative;
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
    margin-right: 0px;
    margin-bottom: 8px;
    position: relative;
    top: 7px;
    width: 95px;
}


.banner-section .join-now-box .joun-button .custom-button {
    padding: 6px 45px;
}

.banner-section .join-now-box .single-option .option label {
    text-transform: none;
}

.banner-section .join-now-box .single-option .title {
    padding-bottom: 8px;
}

input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-feature-settings: inherit;
    font-variation-settings: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0.5em;
}

.banner-section .join-now-box .single-option .option label {
    font-size: 18px;
}
</style>
