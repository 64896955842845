<template>
    <section class="log-reg">
        <div class="container">
            <div class="row justify-content-center">
                <div class="image image-log">
                </div>
                <div class="col-lg-7">
                    <div class="log-reg-inner">
                        <div class="main-content inloginp">
                            <div class="form-group">
                                <label for="">Discount Code</label>
                                <input v-model="data.discountcode" type="text" class="my-form-control"
                                    placeholder="Enter Your Discount Code">
                            </div>
                            <div class="button-wrapper">
                                <button @click="submit()" type="submit" class="custom-button">Use Code</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Toast :data="data" ref="toast"></Toast>
    </section>
</template>
<script>
import Toast from "@/components/controls/toast.vue";
import { config } from "@/config";
import { userguid, authenticationHeader } from  '../../utils/auth'
import axios from "axios";

export default {
    components: {
        Toast
    },
    data() {
        return {
            data: {
                discountcode: "",
                type: "error",
                message: "",
                title: "Discount Code",
                duration: 5000,
                show: false,
            },
        };
    },
    methods: {
        submit() {
            if (this.data.discountcode == "") {
                this.data.message = "Please enter discount code";
                this.$refs.toast.show();
            } else {
                console.log(this.data.discountcode)
                // Post the code to the server and message back response
                axios.post(config.APP_API + "/payment/token/" + userguid(), {
                    token: this.data.discountcode,
                },
                authenticationHeader()
                ).then((response) => {
                    if (response.data.status == "error") {
                        this.data.message = response.data.message;
                        this.$refs.toast.show();
                    } else {
                        this.data.message = "The discount has been applied to your membership";
                        this.data.type = "success";
                        this.$refs.toast.show();
                    }
                }).catch((error) => {
                    this.data.message = error.response.data.message;
                    this.$refs.toast.show();
                }
                );
            }
        }
    }
}
</script>
<style scoped></style>