<template>
    <!-- Start your code here -->
    <div class="panel panel-default event">
        <div class="panel-body row">
            <div class="rsvp col-xs-2 col-sm-2">
                <i>18</i>
                <i>nov</i>
                <div class="hidden-xs">
                    <span class="fa fa-thumbs-up fa-2x"></span>
                    <span class="fa fa-thumbs-down fa-2x"></span>
                </div>
            </div>
            <div class="info col-xs-8 col-sm-7">
                {{ event.title }}
                <div class="visible-xs">{{ event.description }}</div>
                <div class="hidden-xs">
                    <ul class="nav nav-tabs" role="tablist">
                        <li role="presentation" class="active"><a href="#location" aria-controls="location" role="tab"
                                data-toggle="tab">Location</a></li>
                        <li role="presentation"><a href="#profile" aria-controls="profile" role="tab"
                                data-toggle="tab">Profile</a></li>
                    </ul>
                    <!-- Tab panes -->
                    <div class="tab-content">
                        <div role="tabpanel" class="tab-pane active" id="location">Location</div>
                        <div role="tabpanel" class="tab-pane" id="profile">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla mattis feugiat sem, eu
                            sagittis
                            libero. Duis non odio ut nibh volutpat tempus eget interdum elit.
                        </div>
                    </div>
                </div>
            </div>
            <div class="author col-xs-2 col-sm-3">
                <div class="profile-image">
                    <img :src="returnImageString" v-on:click="viewProfile" />
                </div>
                <div class="profile hidden-xs">
                    <strong>Robert White</strong>
                    <article>Event leader and founder of this group</article>
                    <div class="links hidden-sm">
                        <i class="fa fa-github-square fa-2x col-xs-3"></i>
                        <i class="fa fa-google-plus-square fa-2x col-xs-3"></i>
                        <i class="fa fa-facebook-square fa-2x col-xs-3"></i>
                        <i class="fa fa-linkedin-square  fa-2x col-xs-3"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import { userguid, authenticationHeader, loggedin } from '@/utils/auth';
import { config } from '@/config';
import axios from 'axios';

export default {
    name: 'EventCardVue',
    components: {

    },
    props: {
        event: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            config: config,
            user: {},
        }
    },
    setup() {

        return {
            userguid: userguid(),
        }
    },
    methods: {
        currentRouteName() {
            return this.$route.name;
        },
        getData() {

        },
        viewProfile() {
            this.$router.push("/member/");
        },
        tomorrowSearch() {
            this.$router.push("/events/tomorrow");
        },
        nextweekendSearch() {
            this.$router.push("/events/nextweekend");
        },
        choosedateSearch() {
            this.$router.push("/events/choosedate");
        },
        upcomingSearch() {
            this.$router.push("/events/upcoming");
        },
        createEvent() {
            this.$router.push("/events/create");
        },
    },
    created() {
        axios.get(config.APP_API + '/user/profile/' + this.userguid,
            authenticationHeader())
            .then((response) => {
                this.user = response.data[0];
            })
            .catch((error) => {
                console.log(error)
            })
    },
    mounted() {
        if (!loggedin()) {
            this.$router.push({ name: 'Home' });
        }
        else {
            this.getData();
        }

    }, 
    computed: {
        returnImageString() {
            return config.IMAGE_API + '/images/user/' + this.event.userguid + '/index/1';
        },
    }

}
</script>
<style scoped>
.hidden-xs {
    display: none;
}

.event .panel-body {
    background: #fff5dd;
    border: 1px solid #a66715;
    padding: 0;
    margin: 0;
    height: 86px;
    overflow: hidden;
}

.event .panel-body>div {
    padding: 0 10px;
}

.event .panel-body .rsvp {
    border-left: none;
    padding: 0;
    text-align: center;
    position: relative;
    background: #c13202;
    color: white;
    height: 100%;
}

.event .rsvp>div {
    font-size: 12px;
    position: absolute;
    bottom: 0;
    background: #fbe9b7;
    width: 100%;
}

.event .rsvp i {
    font-size: 2em;
    display: block;
    position: relative;
    top: 17%;
    margin-bottom: 5px;
}

.event .rsvp i:last-of-type {
    font-size: 1.5em;
    margin-top: -23px;
}

.event .rsvp span {
    cursor: pointer;
    padding: 0 5px;
    margin: 5px 0;
    width: 45%;
}

.event .rsvp span:first-of-type {
    border-right: 1px solid white;
}

.event .rsvp span:hover {
    color: #ffff8e;
}

.event .info {
    font-size: 20px;
    font-weight: bold;
}

.event .info>div,
.event .info>ul {
    font-size: 12px;
}

.event .author .profile-image {
    position: absolute;
    background: white;
    padding: 3px;
    width: 5em;
    border-radius: 50%;
    border: 1px solid #CCC;
    box-sizing: content-box;
   /* z-index: 1;*/
    top: 4px;
    width: 50%;
}

.event .author img {
    width: 100%;
    border-radius: 50%;
}

/* Overwrites */
.nav-tabs a {
    background: #90CAF9;
    color: white;
}

.info .nav-tabs li.active a {
    background-color: #448AFF;
    color: white;
}

.info .nav-tabs li:hover a,
.info .nav-tabs li.active:hover a {
    background: #00BCD4;
    color: white;
}

.tab-content {
    background: white;
    border-radius: 0 10px;
    padding: 10px;
}


/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {

    .hidden-xs {
        display: block;
    }

    .event .panel-body {
        height: 158px;
    }

    .event .rsvp {
        min-height: 100%;
    }

    .event .rsvp i {
        font-size: 4em;
        top: 5%;
    }

    .event .panel-body .author {
        position: relative;
        padding: 10px;
        height: 100%;
    }

    .event .profile {
        position: relative;
      /*  z-index: 0;*/
        border-left: 2px solid white;
        top: -5px;
        padding-left: 55px;
        height: 100%;
        left: 10%;
    }

    .event .profile strong {
        display: block;
        margin-bottom: 5px;
    }

    .event .author .profile i {
        color: #2c1800;
        padding-left: 0;
    }

    .event .profile .links {
        position: absolute;
        bottom: 0;
    }

    .event .profile article {
        padding: 0 41px 0 0;
    }

    .event .author .profile i:hover {
        color: #2c1800;
    }

    .event .author .profile-image {
        position: absolute;
        background: white;
        padding: 3px;
        width: 30%;
        border-radius: 50%;
        border: 1px solid #CCC;
        box-sizing: content-box;
      /*  z-index: 1; */
        top: 13px;
    }

    .event .author img {
        width: 100%;
        border-radius: 50%;
    }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .event .profile {
        left: 17%;
    }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}</style>