module.exports.config = {
  BASEURL: window.location.origin,
  APP_API: process.env.APP_API
    ? process.env.APP_API
    : window.location.origin.match("localhost")
    ? "http://localhost:4001"
    : "https://loveappapi.honeybee.rsvp",
  IMAGE_API: process.env.IMAGE_API
    ? process.env.IMAGE_API
    : window.location.origin.match("localhost")
    ? "http://localhost:4040"
    : "https://loveappimages.honeybee.rsvp",
  FACEBOOK_APP_ID: "766988808319856",
  Google:{
    MAPS_API_KEY: "AIzaSyBXgrOQOsFSIfJkVXrD52heQj2ApT-NwLw",
  GOOGLE_AUTH_CLIENT_ID:
    "817945129341-sg44rqcivuuf4sq1ggtcuhg4ngvvbbau.apps.googleusercontent.com",
  },
  RELEASE_COUNTRIES: {
    //   "US": "United States",
    //   "UK": "United Kingdom",
    //   "CA": "Canada",
    //   "DE": "Germany",
    //   "FR": "France",
    AU: "Australia",
  },
  DEBUG: process.env.DEBUG ? process.env.DEBUG : true,
  FEATURES: {
    GoogleOAuth: false,
    FacebookOAuth: false,
    MobileSignIn: false,
    MobileSignUp: false,
    NaughtyMode: false,
    Settings: false,
    SocialAuth: false,
    Upgrades: true,
    Events: false,
    Groups: false,
  },
  COOKIE_NAME: "loveapp",
  events: {
    LOGGED_IN_STATUS_CHANGE: "LOGGED_IN_STATUS_CHANGE",
    MENU_CLOSE: "MENU_CLOSE",
    USER_PROFILE_IMAGE_SELECTED: "USER_PROFILE_IMAGE_SELECTED",
    USER_PROFILE_IMAGE_UPLOADED: "USER_PROFILE_IMAGE_UPLOADED",
    USER_PROFILE_IMAGE_DELETED: "USER_PROFILE_IMAGE_DELETED",
    USER_PROFILE_IMAGE_UPDATED: "USER_PROFILE_IMAGE_UPDATED",
    USER_PROFILE_IMAGE_COUNT_UPDATED: "USER_PROFILE_IMAGE_COUNT_UPDATED",
    USER_PROFILE_IMAGE_INDEX_UPDATED: "USER_PROFILE_IMAGE_INDEX_UPDATED",
    USER_STATE_CHANGE: "USER_STATE_CHANGE",
  },
  amplitude: {
    API_KEY: "aa856b7dc402422890e31e7e5252369d",
  },
  RESTRICTED: "restricted",
  PUBLIC: "public",
  Notification: {
    publicKey:
      "BITdfwe6D9XR-vX0gsRSPaOKLkFuXwDLTrJjuVgfIjAsPMk3Z_FeBHoHuTNxuRNVeCcdTCMz577c84K66yWSgcE",
  },
  STRIPE: {
    PUBLISHABLE_KEY: process.env.PUBLISHABLE_KEY
      ? process.env.PUBLISHABLE_KEY
      : "pk_test_51HucxgJxj1kvyy0vdH8hmOHAwb6DiDnpR4XWC2LzW0PUmJq96Fm5UfLm0b4Kzvgz0FCpolimJ8QyxtPLSV21DVli00c0LqbZ0w",
    SECRET_KEY: process.env.STRIPE_SECRET_KEY
      ? process.env.STRIPE_SECRET_KEY
      : "",
    WEBHOOK_SECRET: "",
    STATIC_DIR: "",
    SUCCESS_URL: process.env.SUCCESS_URL
      ? process.env.SUCCESS_URL
      : window.location.origin.match("localhost")
      ? "http://localhost:8080/success"
      : "https://loveappapi.honeybee.rsvp/success",
    CANCEL_URL: process.env.FAILURE_URL
      ? process.env.FAILURE_URL
      : window.location.origin.match("localhost")
      ? "http://localhost:8080/failure"
      : "https://loveappapi.honeybee.rsvp/failure",
  },
};
