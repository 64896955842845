<template>
  <Cookie></Cookie>
  <!-- ==========Preloader========== -->
  <div class="preloader">
    <div class="preloader-inner">
      <div class="preloader-icon">
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
  <!-- ==========Preloader========== -->

  <!-- ==========Overlay========== -->
  <div class="overlay"></div>
  <a href="#" class="scrollToTop">
    <i class="fas fa-angle-up"></i>
  </a>
  <!-- ==========Overlay========== -->

  <NavBar></NavBar>
  <NoNavBar v-show="false"></NoNavBar>
  <Debug></Debug>
  <router-view></router-view>
</template>

<script>
import Debug from './components/controls/debug.vue';
import NavBar from './components/navigation/NavBar.vue'
import NoNavBar from './components/navigation/NoNavBar.vue';
//import Cookie from './components/platform/Cookies.vue';
import { loggedin } from './utils/auth';
import { config } from './config'
import Analytics from 'analytics'
import amplitudePlugin from '@analytics/amplitude'

const analytics = Analytics({
  app: 'love-app',
  plugins: [
    amplitudePlugin({
      apiKey: config.amplitude.API_KEY,
      // See options at https://bit.ly/3dRdZnE
      options: {
        trackingOptions: {
          ip_address: false
        }
      }
    })
  ]
})

/* Track a page view */
analytics.page()



//import testsidepanel from './components/testsidepanel.vue';

export default {
  name: 'App',
  components: {
    NavBar,
    NoNavBar,
    Debug,
  //  Cookie,
  },
  data() {
    return {
      isLoggedIn: this.loggedin(),
      user: {
        name: 'Trevor',
        email: 'trevor.hutt@gmal.com',
        password: 'password',
        password_confirmation: 'password',
      },
    }
  },
  computed: {
    config() { return config; },
  },
  methods: {
    loggedin() {
      return loggedin();
    }
  },
  mounted() {
  },
}
</script>
<style>
.material-symbols-outlined {
  font-size: 32px;
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48
}


.custom-button {
  background-image: -o-linear-gradient(284deg, rgb(211, 148, 0) 0%, rgb(211, 148, 0) 100%) !important;
  background-image: linear-gradient(166deg, rgb(211, 148, 0) 0%, rgb(211, 148, 0) 100%) !important;
  -webkit-box-shadow: 0.872px 9.962px 20px 0px rgba(12, 78, 165, 0.3) !important;
          box-shadow: 0.872px 9.962px 20px 0px rgba(12, 78, 165, 0.3) !important;
  padding: 11px 35px;
  font-weight: 600;
  color: #ffffff;
  border-radius: 50px;
  display: inline-block;
  border: 0px;
  cursor: pointer;
  width: auto;
  height: auto;
}
a.custom-button:hover, .custom-button:hover {
  color: #fff !important;
  -webkit-box-shadow: 1px 10px 20px 0px rgba(12, 78, 165, 0.43);
          box-shadow: 1px 10px 20px 0px rgba(12, 78, 165, 0.43);
}


</style>

