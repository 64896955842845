<template>
    <SettingsPanel></SettingsPanel>
  <br/>
  <router-link to="/login">Login Page</router-link>
  <br/>
  <router-link to="/register">Registration Page</router-link>
  <br/>
  <router-link to="/signup">Sign Up</router-link>
  <br/>
  <router-link to="/forgotpassword">Forgot Password Page</router-link>
  <router-view></router-view>
</template>
<script>
import { loggedin } from '@/utils/auth';
import SettingsPanel from './SettingsPanel.vue'

export default {
    name: 'SettingsVue',
    components:{
        SettingsPanel
    },
    mounted() {
        if (!loggedin()) {
            this.$router.push('/home');
        }
    }
}
</script>
<style>
</style>