<template>
    <a class="nav-link" @click.prevent="menuLinkClick">
        <div class="material-symbols-outlined mr-3">
            {{ icon }}
            <span class="open-label">{{ itemname }}</span>
        </div>
    </a>
</template>
<script>
import { isMobile } from '@/utils/globalFunctions';
import { config } from '../../config';

export default {
    name: 'MenuItemIcon',
    props: ['icon', 'itemname', 'path', 'navstate', 'collapsed', 'method'],
    data() {
        return {
            windowWidth: window.innerWidth,
        }
    },
    computed: {
        isMobileDevice() { return isMobile },
    },
    methods: {
        menuLinkClick() {
            window.dispatchEvent(
                new CustomEvent(config.events.MENU_CLOSE, {
                    detail: {
                        menu: "closed",
                    },
                })
            )
            if (this.method) {
                this.method();
            }
            else {
                try {
                    window.location = this.path;
                    //this.$router.push(this.path);
                }
                catch (error) {
                    console.log(error)
                }
            }
        },
    },
    mounted() {
        window.onresize = () => {
            this.windowWidth = window.innerWidth;
        }
    }
}
</script>
<style scoped>
.open-label {
    display: inline;
    font-family: "Fira Sans", sans-serif;
    color: #e9eeff;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    top: -12px;
    left: -21px
}

.open-icon {
    display: inline;
}


</style>