<template>
    <div id="chat" style="margin-top: 100px;">
        <div class="container">
            <div class="col-lg-6 offset-lg-3">
                <ProfileMini :fromuser="this.chatters.seconduserid" @click="review()"></ProfileMini>
                <div class="chat_container">
                    <!-- Chat Box-->
                    <div class="px-3 py-3" v-for="message in messages" :key="message.id">
                        <ReceiverMessage v-if:="message.fromuserid == chatters.seconduserid" :datetime=chatDateTime(message.created)
                            :message=message>
                        </ReceiverMessage>
                        <SenderMessage v-if:="message.fromuserid == chatters.firstuserid" :datetime=chatDateTime(message.created)
                            :message=message>
                        </SenderMessage>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="send">
                            <div class="form-group">
                                <div class="input-group-append">
                                    <input id="messageInput" type="text" class="form-control" v-model="newMessage"
                                        placeholder="Enter message here" />
                                    <button id="button-addon2" type="submit" class="btn btn-link" style="width: 25px;"> <i
                                            class="fa fa-paper-plane"></i></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ReceiverMessage from './MessageReceiver.vue';
import SenderMessage from './MessageSender.vue';
import ProfileMini from '../profile/ProfileMini.vue';
import axios from 'axios';
import { chatDateTime } from '@/utils/globalFunctions'
import { config } from "../../config.js";
import { io } from 'socket.io-client';
import { ref, reactive } from "vue";
import { userguid, checkLogin, authenticationHeader } from "@/utils/auth.js"
import router from "../../routes.js"

export default {
    name: "ChatVue",
    components: {
        ProfileMini,
        ReceiverMessage,
        SenderMessage,
    },
    setup() {
        checkLogin(window.location.pathname);
        //Get the userguid from the URL for the user you are chatting with
        const touser = window.location.pathname.split("/")[2]
        const chatters = {
            firstuserid: userguid(),
            seconduserid: touser,
            withCredentials: false,
            query: {
                firstuserid: userguid(), seconduserid: touser
            }
        };

        let newMessage = ref(null);
        let typing = ref(false);
        let ready = ref(true);
        let info = reactive([]);
        let connections = ref(0);
        const messages = reactive([]);
        const username = ref(null);
        getData();

        let socket = io(config.APP_API, chatters);


        window.onbeforeunload = () => {
            socket.emit("leave", username.value);
        };

        socket.on("chat-message", (socketid, data) => {
            console.log("Data: ", data)
            messages.push(data);
            gotoLastMessage();
        });

        socket.on("typing", (data) => {
            typing.value = data;
        });

        socket.on("stopTyping", () => {
            typing.value = false;
        });

        socket.on("joined", (data) => {
            console.log(data)
            info.push({
                username: data.name,
                userguid: data.userguid,
                type: "joined",
            });

            /// messages.push(...data.message);

            setTimeout(() => {
                info.length = 0;
            }, 5000);
        });

        socket.on("leave", (data) => {
            info.push({
                username: data,
                type: "left",
            });

            setTimeout(() => {
                info.length = 0;
            }, 5000);
        });

        socket.on("connections", (data) => {
            connections.value = data;
        });

        /*watch(newMessage, (newMessage, preNewMessage) => {
            console.log(preNewMessage)
            newMessage
                ? socket.emit("typing", userguid())
                : socket.emit("stopTyping");
        });
*/
        function send() {
            if (newMessage.value) {
                var date = new Date();
                let createdDate = "" + Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
                    date.getUTCDate(), date.getUTCHours(),
                    date.getUTCMinutes(), date.getUTCSeconds());
                    createdDate = createdDate.substring(0,10)
                const msg = {
                    message: newMessage.value,
                    fromuserid: userguid(),
                    touserid: chatters.seconduserid,
                    created: createdDate,
                    receivermessage: true,
                }
                if (config.debug) console.log(msg)

                socket.emit("chat-message", socket.id, msg);
                newMessage.value = null;
                gotoLastMessage();
            }
        }

        function addUser() {
            ready.value = true;
            socket.emit("joined", { username: username.value, userguid: userguid(), });
        }

        async function getData() {
            axios.get(config.APP_API + '/messages/from/' + chatters.firstuserid + '/to/' + chatters.seconduserid, authenticationHeader())
                .then((response) => {
                    response.data.forEach(message => {
                        messages.push({
                            message: message.message,
                            type: 1,
                            fromuserid: message.fromuserid,
                            created: message.created,
                            receivermessage: (userguid() == message.fromuserid),
                            data: message,
                        });
                    })
                }).then(() => {
                    console.log("loaded");
                })
                .catch((error) => {
                    console.log(error)
                })
        }

        function review() {
            router.push({ name: 'MemberView', params: { id: chatters.seconduserid } })
        }
        function gotoLastMessage() {
            let el = document.getElementById("messageInput");
            el.scrollIntoView({ behavior: "smooth" });
        }

        return {
            addUser,
            chatDateTime,
            send,
            gotoLastMessage,
            newMessage,
            messages,
            typing,
            username,
            ready,
            info,
            connections,
            chatters,
            review,
        };
    },
    updated() {
        console.log("ready")
        this.gotoLastMessage();
    },
};
</script>
<style>
.chat_container {
    max-width: 5000px;
    height: 500px;
    background-color: white;
    overflow: auto;
    padding: 5px 5px 0px 5px;
}
</style>