<template>
  <!-- REGISTRATION FORM -->
  <section class="log-reg">
    <div class="container" style="margin-top: 100px;">
      <div class="row justify-content-center">
        <div class="image image-log">
        </div>
        <div class="col-lg-7">
          <div class="log-reg-inner">
            <div class="main-content inloginp">
              <div class="form-group">
                <label for="">Email Address*</label>
                <input v-model="data.email" type="email" class="my-form-control" placeholder="Enter Your Email">
              </div>
              <div class="form-group">
                <label for="">User name*</label>
                <input v-model="data.username" type="text" class="my-form-control" placeholder="User Name...">
              </div>
              <div class="button-wrapper">
                <button @click="submit()" type="submit" class="custom-button">Sign Up with Email</button>
              </div>
              <div class="or">
                <p>Already have an account?</p>
              </div>
              <div class="button-wrapper">
                <button @click="signin()" type="submit" class="custom-button">Sign In</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Toast :data="data" ref="toast"></Toast>
  </section>
</template>
<script>
import router from '@/routes';
import { config } from '@/config.js'
import Toast from './controls/toast.vue'
import axios from 'axios'

export default {
  name: "RegistrationVue",
  components: {
    Toast,
  },
  data() {
    return {
      data: {
        email: "",
        username: "",
        title: "",
        message: "",
        registrationguid:'',
      }
    }
  },
  mounted(){
    axios.get(config.APP_API + '/register').then(
      (response) => {
        this.data.registrationguid = response.data.registrationguid
      }
    ).catch((error) => {
      console.log(error)
    })
this.data.registrationguid
  },
  methods: {
    emailAddressFormatError() {
      //New account is required
      this.data.title = "Email Address Error";
      this.data.message = "Check your email is correct"
      this.$refs.toast.show();
    },
    usernameError() {
      //New account is required
      this.data.title = "Username Error";
      this.data.message = "Please enter a username"
      this.$refs.toast.show();
    },

    async submit() {
      //Check if the email address is in user and send email to account to login
      if (!this.data.email.includes("@")) {
        this.emailAddressFormatError();
      }
      else if (!this.data.username) {
        this.usernameError();
      }
      else {
        //Send email to user to confirm registration
        await axios.post(config.APP_API + '/register', {
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(this.data)
        }).then(
          (response) => {
            console.log(response.data)
            // Send email with login link
            router.push('/checkemail')
          });
      }

      // Get a registration id from the url
      /*await axios.get(config.APP_API + '/register')
        .then((response) => {
          console.log(response.data)
          const registrationid = response.data[0].registrationid;
          axios.post(config.APP_API + '/register/' + registrationid, {
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
          }).then((response) => {
            console.log(response.data)
            router.push({ path: 'Members' })
          })
            .catch((error) => {
              console.log(error)
            });
        })
        .catch((error) => {
          console.log(error)
        })*/
    },


  },
}
</script>
<style scoped>
.registration {
  position: relative;
  top: 100px;
}
</style>