import { createApp } from "vue";
import App from "./App.vue";
import routers from "./routes";
import VueCookies from 'vue-cookies'
import SlimCropper from 'vue-slim-cropper'
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";


import 'cropperjs/dist/cropper.css';
import "bootstrap/dist/css/bootstrap.css";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.js";

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import './index.css';

import { config } from "./config";

import vue3GoogleLogin from "vue3-google-login";



const app = createApp(App)

app.use(bootstrap);
app.use(routers);
app.use(VueCookies);
app.use(SlimCropper);
app.component("v-select", vSelect);


app.use(vue3GoogleLogin, {
  clientId: config.GOOGLE_AUTH_CLIENT_ID,
  scope: "email",
  prompt: "consent",
});

app.mount("#app");
