<template>
  <div class="margin-top:200px;">
    <h3>Congrats</h3>
  </div>
</template>

<script>
import Stripe from "stripe";
import { config } from "../../config";
import axios from "axios";
import { authenticationHeader } from "@/utils/auth";

export default {
  name: "SuccessVue",
  data() {
    return {};
  },
  methods: {
    async getPaymentDetails() {
      const sessionId = this.$route.query.session_id;
      const stripe = Stripe("sk_test_51HucxgJxj1kvyy0vg1Dg6WIF5M5qFy0XziR7MAyOqHPn6jSwwayTHsCpA7oe2KgXTOrRiHzvastsVYsVcnV4C6oE00lrPB1rZC"); //config.STRIPE.SECRET_KEY);
      const session = await stripe.checkout.sessions.retrieve(sessionId);
      const items = await stripe.checkout.sessions.listLineItems(sessionId);
      const paymentIntent = await stripe.paymentIntents.retrieve(session.payment_intent);
      console.log(items);
      //Store the payment and update the user account
      const postJSON = {
        userguid: session.client_reference_id,
        paymentintent: paymentIntent.id,
        status: paymentIntent.status,
        description: paymentIntent.description,
        amount: paymentIntent.amount,
        created: paymentIntent.created,
        customer: paymentIntent.customer,
        currency: paymentIntent.currency,
        priceid: items.data[0].price.id,
      };
      if (config.DEBUG) console.log(postJSON);
      axios.post(config.APP_API + "/payment", postJSON,
        authenticationHeader())
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  mounted() {
    this.getPaymentDetails();
  }
};
</script>

<style lang="scss" scoped></style>
