import { config } from "@/config";
import { setState } from "./globalFunctions";


export const GoogleAuth = async (googleToken) => {
  const response = await fetch(config.APP_API + "/login/google", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(googleToken),
  });
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  } else {
    const session = await response.json();
    if (session) {
      setAuthorization(session);
      return true;
    }
  }
};

export const userguid = () => {
  let result = localStorage.getItem("user");
  if (!result) {
    result = ""; //"5e6dc0c2-b30b-4470-a20d-a987fe50f3bc"; //development only, remove for production
  }
  return result;
};

export const authenticationHeader = () => {
  return {
    headers: {
      // Overwrite Axios's automatically set Content-Type
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    },
  };
};

export const authenticationHeaderMultiPart = () => {
  return {
    headers: {
      // Overwrite Axios's automatically set Content-Type for multipart/form-data
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    },
  };
};

export const setAuthorization = (session) => {
  localStorage.setItem("jwt", session.token);
  localStorage.setItem("user", session.userguid);
  window.dispatchEvent(
    new CustomEvent(config.events.LOGGED_IN_STATUS_CHANGE, {
      detail: {
        jwt: localStorage.getItem("jwt"),
        user: localStorage.getItem("user"),
        loggedin: true,
      },
    })
  );
};

export const loggedin = () => {
  if (localStorage.getItem("jwt")) {
    if (localStorage.getItem("jwt").length > 0) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const logout = () => {
  localStorage.removeItem("jwt");
  localStorage.removeItem("user");
  setState(config.PUBLIC);
  localStorage.removeItem("state");
  window.dispatchEvent(
    new CustomEvent(config.events.LOGGED_IN_STATUS_CHANGE, {
      detail: {
        jwt: "",
        user: "",
        registration_guid: "",
        loggedin: false,
      },
    })
  );
};

export const checkLogin = (returnURL) => {
  if (userguid() == null || userguid() == "") {
    window.location.href = "/login?redirect=" + returnURL;
  }
};
