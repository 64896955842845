<template>
    <header class="header header-section" style="background-color: transparent">
        <div class="container">
            <div class="header-wrapper">
                <div class="logo">
                    <a href="/home">
                        <img src="/assets/images/logo/logo.png" alt="logo">
                    </a>
                </div>
            </div>
        </div>
    </header>
</template>
<script>

export default {
    name: 'NoNavBarVue',
}
</script>