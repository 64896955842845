<template>
    <section class="log-reg">
        <div class="container" style="margin-top: 100px;">
            <div class="row justify-content-center">
                <div class="image image-log">
                </div>
                <div class="col-lg-7">
                    <div class="log-reg-inner">
                        <div class="main-content inloginp">
                            Check your email for an account verification link.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

export default {
    name: 'CheckEmailVue',
}
</script>