<template>
<FakeDoor :title="title" :message="message" :eventname="eventname"></FakeDoor>
</template>
<script>
import { defineComponent } from 'vue';
import FakeDoor from '../platform/FakeDoor.vue';

export default defineComponent({
  name: 'VideoUpload',
  components: {
    FakeDoor,
},
  data(){
    return{
      title: 'Video Upload Coming Soon',
      message: 'Thank you for your interest in uploading video.',
      eventname: 'Video Upload Coming Soon',
    }
  },
  },
);
</script>