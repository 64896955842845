<template>
    <div class="join-now-box wow fadeInUp">
        <div class="single-option age">
            <p class="title">
                Ages
            </p>
            <select name="agefrom" ref="agefrom" class="select-bar ml-3">
                <option value="18">18</option>
                <option value="20">20</option>
                <option value="24">24</option>
                <option value="26">26</option>
                <option value="28">28</option>
                <option value="30">30</option>
                <option value="35">35</option>
                <option value="40">40</option>
                <option value="45">45</option>
                <option value="50">50</option>
            </select>
            <select name="ageto" ref="ageto" class="select-bar ml-3">
                <option value="30">30</option>
                <option value="35">35</option>
                <option value="40">40</option>
                <option value="45">45</option>
                <option value="50">50</option>
                <option value="55">55</option>
                <option value="60">70</option>
                <option value="60+">80+</option>
            </select>
        </div>
        <div class="joun-button mt-4" style="float: left;margin: 8%;">
            <button class="custom-button" @click="back()">Back</button>
        </div>
        <div class="joun-button mt-4">
            <button class="custom-button" @click="joinNow()">Next</button>
        </div>
    </div>
    <Toast :data="data" ref="toast"></Toast>
</template>
<script>
import Toast from '../controls/toast.vue'
//import axios from 'axios'
import { config } from '@/config';
//import { authenticationHeader } from '@/utils/auth';


export default {
    components: {
        Toast,
    },
    props: ['target'],
    data() {
        return {
            data: {
                post: {},
            },

        }
    },
    computed: {
        config() {
            return config;
        },
    },
    methods: {
        validform() {
            return true;
        },
        back() {
            this.$router.push('/signup/seeking');
        },
        async joinNow() {
            if (this.validform()) {
                this.data.post.agefrom = this.$refs.agefrom.value;
                this.data.post.ageto = this.$refs.ageto.value;
                let registration = JSON.parse(localStorage.getItem("registration"))
                registration.agefrom = this.data.post.agefrom;
                registration.ageto = this.data.post.ageto;
                localStorage.setItem("registration", JSON.stringify(registration))
                this.$router.push('/signup/email');
            } else {
                this.data.title = 'User information'
                this.data.message = 'Please fill in all of the information to complete your registration'
                this.data.showToast = true
                this.$refs.toast.show();
                return false;
            }
        },
        validateForm() {
            this.socialEnabled = this.validform()
        },
    },
    watch: {
        'data.seeking'() {
            this.validateForm();
        },
        'data.gender'() {
            this.validateForm();
        },
    },
}
</script>
<style scoped>
input,
button,
select {
    height: 40px;
}

.select {
    padding-top: 6px;
}

.title {
    position: relative;
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
    margin-right: 0px;
    margin-bottom: 8px;
    position: relative;
    top: 7px;
    width: 95px;
}


.banner-section .join-now-box .joun-button .custom-button {
    padding: 6px 45px;
}

.banner-section .join-now-box .single-option .option label {
    text-transform: none;
}

.banner-section .join-now-box .single-option .title {
    padding-bottom: 8px;
}

input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-feature-settings: inherit;
    font-variation-settings: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0.5em;
}

.banner-section .join-now-box .single-option .option label {
    font-size: 18px;
}

</style>
