<template>
    <section class="user-setting-section" style="margin-top:100px;">
        <div class="container">
            <div class="row">
                <div class="col-xl-8 col-md-7 ">
                    <div class="page-title">
                        Friend Request
                        <div class="right">
                            <a href="#" data-toggle="modal" data-target="#exampleModalCenter">Find Friends</a>
                            <a href="#" data-toggle="modal" data-target="#settingnotification">Settings</a>
                        </div>
                    </div>
                    <div class="frind-box">
                        <div class="single-friend">
                            <div class="left">
                                <img src="assets/images/frequest/1.png" alt="">
                                <div class="content">
                                    <h5 class="name">May Hurt <i class="fas fa-certificate"></i></h5>
                                    <span class="age">21 Years Old</span>
                                    <span class="separator"></span>
                                    <span class="location"><i class="fas fa-map-marker-alt"></i> Paris</span>
                                </div>
                            </div>
                            <div class="right">
                                <a href="#" class="accept">Accept</a>
                                <a href="#" class="ignore">Ignore</a>
                            </div>
                        </div>
                        <div class="single-friend mt-20">
                            <div class="left">
                                <img src="assets/images/frequest/2.png" alt="">
                                <div class="content">
                                    <h5 class="name">May Hurt <i class="fas fa-certificate"></i></h5>
                                    <span class="age">21 Years Old</span>
                                    <span class="separator"></span>
                                    <span class="location"><i class="fas fa-map-marker-alt"></i> Paris</span>
                                </div>
                            </div>
                            <div class="right">
                                <a href="#" class="accept">Accept</a>
                                <a href="#" class="ignore">Ignore</a>
                            </div>
                        </div>
                        <div class="single-friend mt-20">
                            <div class="left">
                                <img src="assets/images/frequest/3.png" alt="">
                                <div class="content">
                                    <h5 class="name">May Hurt <i class="fas fa-certificate"></i></h5>
                                    <span class="age">21 Years Old</span>
                                    <span class="separator"></span>
                                    <span class="location"><i class="fas fa-map-marker-alt"></i> Paris</span>
                                </div>
                            </div>
                            <div class="right">
                                <a href="#" class="accept">Accept</a>
                                <a href="#" class="ignore">Ignore</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "LikesVue",
}
</script>