<template>
    <div class="join-now-box wow fadeInUp">
        <div class="single-option age">
            <div class="main-content inloginp">
                <div class="s-input">
                    <p class="title mb-2">
                        Date&nbsp;of&nbsp;Birth</p>
                    <select name="yearofbirth" ref="yearofbirth" class="select-bar mb-3 mr-3 my-form-control">
                        <option value="1920">1920</option>
                        <option value="1921">1921</option>
                        <option value="1922">1922</option>
                        <option value="1923">1923</option>
                        <option value="1924">1924</option>
                        <option value="1925">1925</option>
                        <option value="1926">1926</option>
                        <option value="1927">1927</option>
                        <option value="1928">1928</option>
                        <option value="1929">1929</option>
                        <option value="1930">1930</option>
                        <option value="1931">1931</option>
                        <option value="1932">1932</option>
                        <option value="1933">1933</option>
                        <option value="1934">1934</option>
                        <option value="1935">1935</option>
                        <option value="1936">1936</option>
                        <option value="1937">1937</option>
                        <option value="1938">1938</option>
                        <option value="1939">1939</option>
                        <option value="1940">1940</option>
                        <option value="1941">1941</option>
                        <option value="1942">1942</option>
                        <option value="1943">1943</option>
                        <option value="1944">1944</option>
                        <option value="1945">1945</option>
                        <option value="1946">1946</option>
                        <option value="1947">1947</option>
                        <option value="1948">1948</option>
                        <option value="1949">1949</option>
                        <option value="1950">1950</option>
                        <option value="1951">1951</option>
                        <option value="1952">1952</option>
                        <option value="1953">1953</option>
                        <option value="1954">1954</option>
                        <option value="1955">1955</option>
                        <option value="1956">1956</option>
                        <option value="1957">1957</option>
                        <option value="1958">1958</option>
                        <option value="1959">1959</option>
                        <option value="1960">1960</option>
                        <option value="1961">1961</option>
                        <option value="1962">1962</option>
                        <option value="1963">1963</option>
                        <option value="1964">1964</option>
                        <option value="1965">1965</option>
                        <option value="1966">1966</option>
                        <option value="1967">1967</option>
                        <option value="1968">1968</option>
                        <option value="1969">1969</option>
                        <option value="1970">1970</option>
                        <option value="1971">1971</option>
                        <option value="1972">1972</option>
                        <option value="1973">1973</option>
                        <option value="1974">1974</option>
                        <option value="1975">1975</option>
                        <option value="1976">1976</option>
                        <option value="1977">1977</option>
                        <option value="1978">1978</option>
                        <option value="1979">1979</option>
                        <option value="1980">1980</option>
                        <option value="1981">1981</option>
                        <option value="1982">1982</option>
                        <option value="1983">1983</option>
                        <option value="1984">1984</option>
                        <option value="1985">1985</option>
                        <option value="1986">1986</option>
                        <option value="1987">1987</option>
                        <option value="1988">1988</option>
                        <option value="1989">1989</option>
                        <option value="1990">1990</option>
                        <option value="1991">1991</option>
                        <option value="1992">1992</option>
                        <option value="1993">1993</option>
                        <option value="1994">1994</option>
                        <option value="1995">1995</option>
                        <option value="1996">1996</option>
                        <option value="1997">1997</option>
                        <option value="1998">1998</option>
                        <option value="1999">1999</option>
                        <option value="2000">2000</option>
                        <option value="2001">2001</option>
                        <option value="2002">2002</option>
                        <option value="2003">2003</option>
                        <option value="2004">2004</option>
                        <option value="2005">2005</option>
                        <option value="2006" selected="selected">2006</option>
                    </select>
                    <select name="monthofbirth" ref="monthofbirth" class="select-bar mb-3">
                        <option value="1">Jan</option>
                        <option value="2">Feb</option>
                        <option value="3">Mar</option>
                        <option value="4">Apr</option>
                        <option value="5">May</option>
                        <option value="6">Jun</option>
                        <option value="7">Jul</option>
                        <option value="8">Aug</option>
                        <option value="9">Sep</option>
                        <option value="10">Oct</option>
                        <option value="11">Nov</option>
                        <option value="12">Dec</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="joun-button mt-4" style="float: left;margin: 8%;">
            <button class="custom-button" @click="back()">Back</button>
        </div>
        <div class="joun-button mt-4">
            <button class="custom-button" @click="joinNow()">Next</button>
        </div>
    </div>
    <Toast :data="data" ref="toast"></Toast>
</template>
<script>
import Toast from '../controls/toast.vue'
import { config } from '@/config';


export default {
    components: {
        Toast,
    },
    props: ['target'],
    data() {
        return {
            data: {
                post: {},
            },

        }
    },
    computed: {
        config() {
            return config;
        },
    },
    methods: {
        validform() {
            return true;
        },
        back() {
            this.$router.push('/signup/location');
        },
        async joinNow() {
            if (this.validform()) {
                this.data.post.yearofbirth = this.$refs.yearofbirth.value;
                this.data.post.monthofbirth = this.$refs.monthofbirth.value;
                let registration = JSON.parse(localStorage.getItem("registration"))
                registration.yearofbirth = this.data.post.yearofbirth;
                registration.monthofbirth = this.data.post.monthofbirth;
                localStorage.setItem("registration", JSON.stringify(registration))
                this.$router.push('/signup/email');
            } else {
                this.data.title = 'User information'
                this.data.message = 'Please fill in all of the information to complete your registration'
                this.data.showToast = true
                this.$refs.toast.show();
                return false;
            }
        },
        validateForm() {
            this.socialEnabled = this.validform()
        },
    },
    watch: {
        'data.seeking'() {
            this.validateForm();
        },
        'data.gender'() {
            this.validateForm();
        },
    },
}
</script>
<style scoped>
input,
button,
select {
    height: 40px;
}

.select {
    padding-top: 6px;
}

.title {
    position: relative;
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
    margin-right: 0px;
    margin-bottom: 8px;
    position: relative;
    top: 7px;
    width: 95px;
}


.banner-section .join-now-box .joun-button .custom-button {
    padding: 6px 45px;
}

.banner-section .join-now-box .single-option .option label {
    text-transform: none;
}

.banner-section .join-now-box .single-option .title {
    padding-bottom: 8px;
}

input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-feature-settings: inherit;
    font-variation-settings: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0.5em;
}

.banner-section .join-now-box .single-option .option label {
    font-size: 18px;
}
</style>
