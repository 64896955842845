<template>
  <!-- LOGIN FORM -->
  <section class="log-reg">
    <div class="container">
      <div class="row justify-content-center">
        <div class="image image-log">
        </div>
        <div class="col-lg-7">
          <div class="log-reg-inner">
            <div class="main-content inloginp">
              <div class="form-group">
                <label for="">Email Address*</label>
                <input v-model="data.email" type="email" class="my-form-control" placeholder="Enter Your Email">
              </div>
              <div class="button-wrapper">
                <button @click="submit()" type="submit" class="custom-button">Sign In</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Toast :data="data" ref="toast"></Toast>
  </section>
</template>
<script>
import { config } from '@/config';
import { logout } from '@/utils/auth'
import axios from 'axios'
import Toast from '../controls/toast.vue'
import router from '@/routes';
export default {
  name: "LoginVue",
  components: {
    Toast,
  },
  data() {
    return {
      data: {
        email: "",
        title: "",
        message: "",
      }
    }
  },
  mounted() {
    //Clear local session token and user
    logout();
  },
  methods: {
    emailError() {
      //New account is required
      this.data.title = "Login Error";
      this.data.message = "Check your email or password and try again"
      this.$refs.toast.show();
    },
    async submit() {
      await axios.post(config.APP_API + '/login/link', (this.data)).then(() => {
          router.push('/checkemail');
      }).catch((error) => {
        // this.data.message = error.response;
        console.log("Response Status: " + error);
        this.emailError();
      })
    }
  }
}
</script>
<style scoped>
.log-reg {
  top: 100px;
}
</style> 