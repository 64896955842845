<template>
    <!-- ==========Banner-Section========== -->
    <section class="banner-section" :class="{ undermenu: isMobileDevice }">
        <img v-if="notIsMobile" class="img1 wow fadeInLeft" :src="heroImage" alt="">
        <div class="container">
            <div class="row">
                <div class="col-xl-4 col-lg-5">
                    <h1 v-if="notIsMobile" class="main-title wow fadeInLeft">
                        Find you're someone
                    </h1>
                    <div v-if="!data.loggedInState">
                        <SignUpStep01Seeking
                            v-if="currentRouteName === 'HomePage' || currentRouteName === 'HomePageRoot' || currentRouteName === 'SignUpSeeking'"
                            :target="'MemberView'"></SignUpStep01Seeking>
                        <SignUpStep02Location v-if="currentRouteName === 'SignUpLocation'" :target="'MemberView'">
                        </SignUpStep02Location>
                        <SignUpStep03DOB v-if="currentRouteName === 'SignUpDOB'" :target="'MemberView'"></SignUpStep03DOB>
                        <SignUpStep04SeekingAge v-if="currentRouteName === 'SignUpSeekingAge'" :target="MemberView">
                        </SignUpStep04SeekingAge>
                        <SignUpStep05Email v-if="currentRouteName === 'SignUpEmail'" :target="'MemberView'">
                        </SignUpStep05Email>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <footer class="footer-section">
        <NewsLetterSignup />
        <FooterLinks></FooterLinks>
    </footer>
</template>
<script>
import SignUpStep01Seeking from './signup/JoinNowStep01Seeking.vue'
import SignUpStep02Location from './signup/JoinNowStep02Location.vue'
import SignUpStep03DOB from './signup/JoinNowStep03DOB.vue'
import SignUpStep04SeekingAge from './signup/JoinNowStep04SeekingAge.vue'
import SignUpStep05Email from './signup/JoinNowStep05Email.vue'
import FooterLinks from './FooterLinks.vue';
import { isMobile, notIsMobile } from '@/utils/globalFunctions'
import { logout, loggedin } from '@/utils/auth/'
import { config } from '../config'

export default {
    name: 'HomePageVue',
    data() {
        return {
            data: {
                loggedInState: false,
            }
        }
    },
    mounted() {
        window.addEventListener(config.events.LOGGED_IN_STATUS_CHANGE, (event) => {
            this.data.loggedInState = event.detail.loggedin;
        });

        this.data.loggedInState = loggedin();

    },
    components: {
        FooterLinks,
        SignUpStep01Seeking,
        SignUpStep02Location,
        SignUpStep03DOB,
        SignUpStep04SeekingAge,
        SignUpStep05Email,
    },
    methods: {
        logout: function () {
            if (this.currentRouteName === 'logout') {
                logout();
                //this.$route.push('/home')
            }
        }
    },
    computed: {
        config() {
            return config;
        },
        isMobileDevice() { return isMobile() },
        notIsMobile() { return notIsMobile() },
        currentRouteName() {
            return this.$route.name;
        },
        heroImage() {
            var image = "assets/images/home/";
            var list = ["home-01.jpg", "home-02.jpg", "home-03.jpg"]
            var index = Math.floor((Math.random()*list.length))
            return image + list[index];
        }

    },
}
</script>
<style>
.undermenu {
    padding: 80px 10px;
}

.hero {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
</style>