<template>
    <div class="card-container">
        <Card></Card>
    </div>
</template>
<script>
import Card from './Cards.vue';
/*import axios from 'axios';
import { config } from '@/config';
import { authenticationHeader, userguid } from '@/utils/globalFunctions';*/

export default {
    name: 'CardsContainerVue',
    components: {
        Card,
    },
}

</script>
<style lang="scss">
@import "../../assets/shared";

.card-container {
position: relative;
top: 80px;
}
</style>