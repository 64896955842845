<template>
    <div class="container">
        <div class="row">
            <div v-for="offer in lineItems" :key="offer.id" class="col">
                <OfferCard :offer="offer" :key="offer.id" :pubkey="publishableKey" :mode="offer.paymenttype" :success="successURL" :line-items="lineItem"
                    :cancel="cancelURL"></OfferCard>
            </div>
        </div>
    </div>
</template>
<script>
import OfferCard from './OfferCard.vue';
import { config } from '@/config';
import { userguid, authenticationHeader, loggedin } from '@/utils/auth';
import axios from 'axios';

export default {
    name: 'OffersVue',
    props: ['superlikes'],
    components: {
        OfferCard
    },
    computed: {

    },
    setup() {


    },
    data() {
        this.publishableKey = config.STRIPE.PUBLISHABLE_KEY;
        return {
            lineItems: [],
            successURL: config.STRIPE.SUCCESS_URL + '?session_id={CHECKOUT_SESSION_ID}',
            cancelURL: config.STRIPE.CANCEL_URL,
            lineItem: [
                {
                    price: 'price_1NqKWuJxj1kvyy0v4D9cpXRB', // The id of the recurring price you created in your Stripe dashboard
                    quantity: 1,
                },
            ],
        }
    },
    methods: {
        async getData() {
            console.log('Getting offers', this.superlikes)
            const clientId = userguid(); // Replace with your client's identifier
            let getOffersURL = config.APP_API + '/offers/user/' + clientId
            if (this.superlikes) {
                getOffersURL = config.APP_API + '/offers/user/' + clientId + '/superlikes'
            }
            axios.get(getOffersURL, authenticationHeader())
                .then((response) => {
                   this.lineItems = response.data;
                   this.lineItems.forEach(element => {
                        element.price = element.sku
                        element.quantity = 1
                    });
                })
                .catch((error) => {
                    console.log(error)
                })
        },

    },
    mounted() {
        if (!loggedin()) {
            this.$router.push('/home');
        } else {
            this.getData();
        }
    }

}
</script>