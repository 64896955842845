<template>
    <!-- ==========Banner-Section========== -->
    <section class="banner-section" :class="{ undermenu: isMobileDevice }">
        <div class="container">
            <div class="row">
                <div class="col-xl-4 col-lg-5">
                    <div v-if="!data.loggedInState">
                        <SignUpMemberStep01 
                            v-if="currentRouteName === 'Connections' || currentRouteName === 'SignUp'"
                        :target="'MemberView'"></SignUpMemberStep01>
                        <SignUpMemberStep02Location v-if="currentRouteName === 'SignUpLocation'" :target="'MemberView'">
                        </SignUpMemberStep02Location>
                        <SignUpMemberStep03DOB v-if="currentRouteName === 'SignUpDOB'" :target="'MemberView'">
                        </SignUpMemberStep03DOB>
                        <SignUpMemberStep04Email v-if="currentRouteName === 'SignUpEmail'" :target="'MemberView'">
                        </SignUpMemberStep04Email>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <footer class="footer-section">
        <NewsLetterSignup />
        <FooterLinks></FooterLinks>
    </footer>
</template>
<script>
import SignUpMemberStep01 from './JoinNowMemberStep01.vue'
import SignUpMemberStep02Location from './JoinNowMemberStep02Location.vue'
import SignUpMemberStep03DOB from './JoinNowMemberStep03DOB.vue'
import SignUpMemberStep04Email from './JoinNowMemberStep04Email.vue'
import FooterLinks from '../FooterLinks.vue';
import { isMobile, notIsMobile } from '@/utils/globalFunctions'
import { logout, loggedin } from '@/utils/auth/'
import { config } from '../../config'

export default {
    name: 'HomePageVue',
    data() {
        return {
            data: {
                loggedInState: false,
            }
        }
    },
    mounted() {
        window.addEventListener(config.events.LOGGED_IN_STATUS_CHANGE, (event) => {
            this.data.loggedInState = event.detail.loggedin;
        });

        this.data.loggedInState = loggedin();

    },
    components: {
        FooterLinks,
        SignUpMemberStep01,
        SignUpMemberStep02Location,
        SignUpMemberStep03DOB,
        SignUpMemberStep04Email,
    },
    methods: {
        logout: function () {
            if (this.currentRouteName === 'logout') {
                logout();
                //this.$route.push('/home')
            }
        }
    },
    computed: {
        config() {
            return config;
        },
        isMobileDevice() { return isMobile() },
        notIsMobile() { return notIsMobile() },
        currentRouteName() {
            return this.$route.name;
        },

    },
}
</script>
<style>
.undermenu {
    padding: 80px 10px;
}

.hero {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
</style>