<template>
    <section>
        <div class="container">
            <div class="row">
                <Offers superlikes="true"></Offers>
            </div>
        </div>
    </section>
</template>
  
<script>
import Offers from './Offers.vue';

export default {
    name: "PaymentsSuperLikesVue",
    components: {
        Offers,
    },
    setup() {
        return {};
    }
};
</script>
  
<style scoped>
section {
    padding: 100px 0;
}

h2 {
    text-align: center;
    margin-top: 50px;
    font-size: 2em;
}
/* End card section */
</style>