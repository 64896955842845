<template>
  <div>
    <div class="slider-component">
      <div class="slidecontainer">
        <ul>
          <li>
            {{ label }} {{ currentValue }} {{ unitString }} <span v-if="unitString == 'cm'">({{ Math.round(currentValue /
              3.048) / 10 }} foot)</span>
            <span v-if="unitString == 'km'">({{ Math.round(currentValue / 0.62137119) }} miles)</span>
          </li>
          <li>
            <input ref="input" v-model="currentValue" type="range" :min="min" :max="max" class="slider"
              @input="onInput">
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import _debounce from 'lodash/debounce';

export default {
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      required: true
    },
    min: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      required: true
    },
    unit: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      currentValue: this.value,
      touched: false,
    };
  },
  emits: ['input'],
  computed: {
    unitString() {
      if (typeof (this.unit) === 'undefined') {
        return ''
      }
      else {
        return this.unit
      }
    },
  },
  watch: {
    value(value) {
      if (!this.touched) this.internalValue = value
    },
  },
  methods: {
    updateInternalValue(event) {
      this.touched = true
      this.updateValue(event.target.value)
    },
    updateValue: _debounce(function (value) {
      this.touched = false
      this.$emit('input', parseInt(this.currentValue));
      this.$emit('update:value', value)
    }, 600),
    onInput() {
      // this.currentValue is a string because HTML is weird
      this.$emit('input', parseInt(this.currentValue));
    }
  },
};
</script>

<style scoped>
.slider-component .slidecontainer {
  width: 100%;
}

.slider-component .slidecontainer .slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background: #c2c2c2;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider-component .slidecontainer .slider:hover {
  opacity: 1;
}

.slider-component .slidecontainer .slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  background: #D8A22E;
  cursor: pointer;
  border-radius: 50%;
}

.slider-component .slidecontainer .slider::-moz-range-thumb {
  width: 24px;
  height: 24px;
  background: #D8A22E;
  cursor: pointer;
  border-radius: 50%;
}

ul {
  padding-left: 0;
}
</style>
