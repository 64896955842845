<template>
    <div class="container">
        <Toast :data="data" ref="toast"></Toast>


        <div class="m-3">
            <input name="userguid" v-bind:value=userguid>
            <div>
                <DoubleRangeSlider @input="getDistance($event)" :value=100 :label="'Distance'" :min=0 :max=200 :unit="'km'">
                </DoubleRangeSlider>
            </div>

        </div>
        <div class="row justify-content-center">
            <div class="profile-main-content">
                <div class="write-post-area">
                    <div class="write-area">
                        <label for="eventtitle">Title</label>
                        <input id="eventtitle" type="text" placeholder="Title" aria-label="event-name">

                        <label for="">Description</label>
                        <textarea v-model="userlocal" placeholder="About the event..."></textarea>
                    </div>
                </div>
                <LocationLookup></LocationLookup>
                <div class="row">
                    <div class="col">
                        <button type="button" class="custom-button" v-on:click="save">Save</button>
                    </div>
                    <div class="col">
                        <button type="button" class="custom-button" v-on:click="cancel">Cancel</button>
                    </div>
                    <div class="col">
                        <button type="button" class="custom-button" v-on:click="remove">Remove</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import DoubleRangeSlider from '../controls/DoubleRangeSlider.vue'
import LocationLookup from '../location/LocationLookup.vue'
import Toast from '../controls/toast.vue'
import { userguid, authenticationHeader, loggedin } from '@/utils/auth';
import { config } from '@/config';
import axios from 'axios';

export default {
    name: 'EventCreateVue',
    components: {
        DoubleRangeSlider,
        Toast,
        LocationLookup
    },
    props: ['user'],
    computed: {
        userlocal: userguid,
    },
    data() {
        return {
            form: {},
            locale: {}
        }
    },
    methods: {
        async getData() {
            this.form.ons = this.userlocal.ons === 1 ? true : false
            this.form.hookups = this.user.hookups === 1 ? true : false
            this.form.dating = this.user.dating === 1 ? true : false
            this.form.sugardating = this.user.sugardating === 1 ? true : false
            this.form.friends = this.user.friends === 1 ? true : false
            this.form.fwb = this.user.fwb === 1 ? true : false
            this.form.user_looking_for = this.user.user_looking_for === 1 ? true : false
            this.form.relationship = this.user.relationship === 1 ? true : false
            this.form.seekingmale = this.user.seekingmale === 1 ? true : false
            this.form.seekingfemale = this.user.seekingfemale === 1 ? true : false
            this.form.seekingother = this.user.seekingother === 1 ? true : false
            this.form.distance = this.user.distance
            this.form.agefrom = this.user.agefrom
            this.form.ageto = this.user.ageto
            this.locale.latitude = this.user.latitude
            this.locale.longatude = this.user.longatude
        },
        async postData() {
            this.form.userguid = this.userguid;
            axios.put(config.APP_API + '/user/preferences',
                {
                    ons: this.form.ons == 1 ? true : false,
                    hookups: this.form.hookups == 1 ? true : false,
                    dating: this.form.dating == 1 ? true : false,
                    sugardating: this.form.sugardating == 1 ? true : false,
                    friends: this.form.friends == 1 ? true : false,
                    fwb: this.form.fwb == 1 ? true : false,
                    user_looking_for: this.form.user_looking_for === null ? '' : this.form.user_looking_for,
                    relationship: this.form.relationship == 1 ? true : false,
                    seekingmale: this.form.seekingmale == 1 ? true : false,
                    seekingfemale: this.form.seekingfemale == 1 ? true : false,
                    seekingother: this.form.seekingother == 1 ? true : false,
                    distance: this.form.distance,
                    latitude: this.locale.latitude,
                    longatude: this.locale.longatude,
                    agefrom: this.form.agefrom,
                    ageto: this.form.ageto,
                    userguid: this.userguid
                },
                authenticationHeader
            ).then((response) => {
                // Handle success
                console.log(response)
            }).catch((error) => {
                // Handle error
                console.log(error)
            });
        },
        getDistance(distance) {
            this.form.distance = distance
        },
        getAgeFrom(agefrom) {
            this.form.agefrom = agefrom
        },
        getAgeTo(ageto) {
            this.form.ageto = ageto
        },
        save() {
            this.postData()
            this.$router.push("/members");
        },
        cancel() {
            this.$router.push("/members");
        },
        remove() {
            this.$router.push("/members");
        }
    },
    mounted() {
        if (!loggedin()) {
            this.$router.push({ name: 'Home' });
        }
        else {
            this.getData();
        }

    }

}
</script>
<style>
.preference-header2 {
    margin-top: 20px;
    margin-bottom: 20px;
}

hr {
    margin-top: 20px;
    margin-bottom: 20px;
}
</style>