<template>
    <div class="card-container">
        <div class="panel">
            <div>
                <img class="sb-title-icon" src="https://fonts.gstatic.com/s/i/googlematerialicons/location_pin/v5/24px.svg"
                    alt="">
                <span class="sb-title">Address Selection</span>
            </div>
            <input type="text" placeholder="Origin" ref="origin" />
            <button class="button-cta">Save</button>
        </div>
        <div class="map" id="gmp-map"></div>
    </div>
</template>
<script>
import { userguid } from '@/utils/auth';
import { config } from '@/config';
//import axios from 'axios';

export default {
    name: 'EventsVue',
    components: {

    },
    data() {
        return {
            config: config,
            user: {},
        }
    },
    setup() {

        return {
            userguid: userguid(),
        }
    },
    methods: {

    },
    created() {

    },
    mounted() {
        /*const autocomplete = new google.maps.places.Autocomplete(this.$refs["origin"]);
        console.log(autocomplete);*/
    },

}
</script>
<style scoped>
body {
    margin: 0;
}

.sb-title {
    position: relative;
    top: -12px;
    font-family: Roboto, sans-serif;
    font-weight: 500;
}

.sb-title-icon {
    position: relative;
    top: -5px;
}

.card-container {
    display: flex;
    height: 500px;
    width: 600px;
}

.panel {
    background: white;
    width: 300px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.half-input-container {
    display: flex;
    justify-content: space-between;
}

.half-input {
    max-width: 120px;
}

.map {
    width: 300px;
}

h2 {
    margin: 0;
    font-family: Roboto, sans-serif;
}

input {
    height: 30px;
}

input {
    border: 0;
    border-bottom: 1px solid black;
    font-size: 14px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
}

input:focus::placeholder {
    color: white;
}

.button-cta {
    align-self: start;
    background-color: #1976d2;
    border: 0;
    border-radius: 21px;
    color: white;
    cursor: pointer;
    font-family: "Google Sans Text", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 27px;
    padding: 3.5px 10.5px;
}
</style>
