<template>
    <section class="section-404">
        <div class="container">
            <div class="thumb-404">
                <img src="/assets/images/404.png" alt="404">
            </div>
            <h3 class="title">Oops.. looks like you got lost :( </h3>
            <a href="/" class="custom-button">Back To Home <i class="flaticon-right"></i></a>
        </div>
    </section>
</template>