<template>
    <div style="margin-top: 100px;">
    </div>
    <section class="profile-section">
        <div class="container">
            <Toast :data="data" ref="toast"></Toast>
            <div class="row justify-content-center">
                <div class="col-xl-4 col-lg-5 col-md-7">
                    <div class="left-profile-area">
                        <div class="profile-about-box">
                            <transition :name="dir">
                                <div class="carousel" @keydown="checkSlide($event)" :key="images">
                                    <img :src="config.IMAGE_API + '/file/' + images[currentIndex]" alt="Carousel Image"
                                        class="carousel-slider" />
                                    <button @click="prevImage" class="btn btn-next"><i
                                            class="fa fa-angle-right"></i></button>
                                    <button @click="nextImage" class="btn btn-prev"><i
                                            class="fa fa-angle-left"></i></button>
                                </div>
                            </transition>
                            <div class="p-inner-content">
                                <h5 class="name">
                                    {{ member.fullname }}
                                </h5>
                                <ul class="p-b-meta-one">
                                    <li>
                                        <span>{{ member.age }} Years Old</span>
                                    </li>
                                    <li v-if="member.location">
                                        <span> <i class="fas fa-map-marker-alt"></i>{{ member.location }}</span>
                                    </li>
                                </ul>
                                <div class="profile-meta-box">
                                    <ul class="p-m-b">
                                        <li>
                                            <a href="#" @click.prevent="openChat()" data-toggle="modal" data-target="#usermessage">
                                                <i class="far fa-envelope"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" @click.prevent="wink" data-toggle="modal"
                                                data-target="#usernotification">
                                                <i class="fas fa-face-smile-wink"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" @click.prevent="goBack">
                                                <i class="fa-solid fa-circle-chevron-up"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2>About {{ member.fullname }}</h2>
                    <p> {{ member.aboutme }}</p>
                    <h2>Looking for</h2>
                    <p> {{ member.lookingfor }}</p>
                    <p v-if="member.ons">One-night-stands</p>
                    <p v-if="member.hookups">Hookups</p>
                    <p v-if="member.dating">Dating</p>
                    <p v-if="member.sugardating">Sugar dating</p>
                    <p v-if="member.friends">Friends</p>
                    <p v-if="member.fwb">Friends with benefits</p>
                    <p v-if="member.relationship">Relationship</p>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios';
import { config } from '../../config';
import { userguid, authenticationHeader } from '../../utils/auth';
import Toast from '../controls/toast.vue'

export default {
    name: 'MemberView',
    components: {
        Toast
    },
    data() {
        return {
            images: [],
            currentIndex: 0,
            member: {}, // Track the index of the currently displayed image
            config: config,
            data: {
                title: '',
                message: '',
                showToast: false,
            },
        };
    },
    computed:{
        userguid: function () {
            return userguid();
        },
    },
    methods: {
        getData() {
            axios.get(config.APP_API + '/member/' + this.$route.params.id, authenticationHeader())
                .then((response) => {
                    this.member = response.data[0];
                    axios.get(config.APP_API + '/member/' + this.$route.params.id + '/images', authenticationHeader())
                        .then((response) => {
                            response.data.forEach((item) => {
                                this.images.push(item.image);
                            })
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        prevImage() {
            this.currentIndex = (this.currentIndex - 1 + this.images.length) % this.images.length;
        },
        nextImage() {
            this.currentIndex = (this.currentIndex + 1) % this.images.length;
        },
        goBack() {
            this.$router.push('/members/' + this.member.guid);
        },

        async wink() {
            axios.post(config.APP_API + '/swipe/wink',
                {
                    userguid: this.userguid,
                    targetuserguid: this.member.guid
                },
                authenticationHeader()).then((response) => {
                    console.log(response)
                    if (typeof this.$route.params.id !== 'undefined') {
                        this.$route.params.id = null;
                    }
                    this.data.title = 'Member Winked';
                    this.data.message = 'Wink sent to ' + this.member.fullname;
                    this.data.showToast = true;
                    this.$refs.toast.show();
                }).catch((response) => { console.log(response) })
        },
        async message() {
            axios.post(config.APP_API + '/swipe/message',
                {
                    userguid: this.userguid,
                    targetuserguid: this.targetuserguid
                },
                authenticationHeader()).then((response) => {
                    console.log(response)
                    if (typeof this.$route.params.id !== 'undefined') {
                        this.$route.params.id = null;
                    }
                    this.getNext();
                }).catch((response) => { console.log(response) })
        },
        openChat() {
            this.$router.push('/chat/' + this.member.guid);
        },
    },
    mounted() {
        this.getData();
    },

}
</script>
<style scoped>
.profile-section p {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.profile-section h2 {
    margin-top: 20px;
    font-weight: 800;
}

.profile-section .left-profile-area .profile-about-box .p-inner-content {
    position: relative;
    padding: 10px 30px 30px;
    text-align: center;
}


.carousel {
    position: relative;
    height: 270px;
    z-index: 10;
    margin-left: 20%;
}

.carousel img {
    border-radius: 8px;
    width: 250px;
    height: 250px;
}

.p-b-meta-two {
    text-align: center;
}

.btn {
    padding: 5px 10px;
    background-color: rgba(0, 0, 0, 0.5);
    border: 1px solid transparent;
    margin: 5px 10px;
    color: #FFF;
    height: 50px;
    width: 50px;
    position: absolute;
    margin-top: -25px;
    z-index: 2;
}

.btn:hover {
    cursor: pointer;
}

.btn:focus {
    outline: none;
}

.btn-next {
    top: 50%;
    right: 0;
}

.btn-prev {
    top: 50%;
    left: -25%;
}

.carousel-slider {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.carousel-slider img {
    width: 100%;
    height: 100%;
}

.slide-left-enter-active {
    animation: slide-left-in 0.5s ease-in;
}

.slide-left-leave-active {
    animation: slide-left-out 0.5s ease-in;
}

@keyframes slide-left-in {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes slide-left-out {
    from {
        transform: translateX(0%);
    }

    to {
        transform: translateX(100%);
    }
}


.slide-right-enter-active {
    animation: slide-right-in 0.5s ease-in;
}

.slide-right-leave-active {
    animation: slide-right-out 0.5s ease-in;
}

@keyframes slide-right-out {
    from {
        transform: translateX(0%);
    }

    to {
        transform: translateX(-100%);
    }
}

@keyframes slide-right-in {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}
</style>