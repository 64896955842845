<template>
    <div class="col-xl-4 col-md-5">
        <div class="accordion" id="accordionExample">
            <div class="card">
                <div class="card-header" id="headingOne">
                    <button class="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                        aria-controls="collapseOne">
                        <div class="icon">
                            <i class="fas fa-user"></i>
                        </div>
                        <span>My Profile</span>
                        <div class="t-icon">
                            <i class="fas fa-plus"></i>
                            <i class="fas fa-minus"></i>
                        </div>
                    </button>
                </div>

                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                    <div class="card-body">
                        <ul class="links">
                            <li>
                                <a href="user-setting.html">Profile Info</a>
                            </li>
                            <li>
                                <a href="user-notification.html">Notifications</a>
                            </li>
                            <li>
                                <a class="active" href="user-friend-request.html">Friend Requests</a>
                            </li>
                            <li>
                                <a href="user-badges.html">Badges</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="headingTwo">
                    <button class="collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo"
                        aria-expanded="false" aria-controls="collapseTwo">
                        <div class="icon">
                            <i class="fas fa-cogs"></i>
                        </div>
                        <span>
                            Account
                        </span>
                        <div class="t-icon">
                            <i class="fas fa-plus"></i>
                            <i class="fas fa-minus"></i>
                        </div>
                    </button>
                </div>
                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                    <div class="card-body">
                        <ul class="links">
                            <li>
                                <a href="user-account-info.html">Account Info</a>
                            </li>
                            <li>
                                <a href="user-change-pass.html">Change Password</a>
                            </li>
                            <li>
                                <a href="user-privicy-setting.html">Privacy Settings</a>
                            </li>
                            <li>
                                <a href="user-verify-account.html">Verified account</a>
                            </li>
                            <li>
                                <a href="user-close-account.html">Close Account</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="headingThree">
                    <button class="collapsed" type="button" data-toggle="collapse" data-target="#collapseThree"
                        aria-expanded="false" aria-controls="collapseThree">
                        <div class="icon">
                            <i class="far fa-credit-card"></i>
                        </div>
                        <span>
                            Subscriptions &amp; Payments
                        </span>
                        <div class="t-icon">
                            <i class="fas fa-plus"></i>
                            <i class="fas fa-minus"></i>
                        </div>
                    </button>
                </div>
                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                    <div class="card-body">
                        <ul class="links">
                            <li>
                                <a href="user-billing.html">Billing &amp; Payout</a>
                            </li>
                            <li>
                                <a href="user-plan.html">Upgrade Membership Plan</a>
                            </li>
                            <li>
                                <a href="user-purchase.html">View Purchase History</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "SettingsPanelVue",
}
</script>