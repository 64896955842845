<template>
    <div class="join-now-box wow fadeInUp">
        <div class="single-option">
            <p class="title">
                I am a
            </p>
            <div class="option ml-3">
                <div class="s-input mr-3">
                    <input v-model="data.gender" type="radio" name="gender" id="male" value="male"><label
                        for="male">Man</label>
                </div>
                <div class="s-input mr-3">
                    <input v-model="data.gender" type="radio" name="gender" id="female" value="female"><label
                        for="female">Woman</label>
                </div>
                <div class="s-input">
                    <input v-model="data.gender" type="radio" name="gender" id="genderother" value="other"><label
                        for="genderother">Other</label>
                </div>
            </div>
        </div>
        <div class="single-option gender">
            <p class="title">
                Seeking
            </p>
            <div class="option ml-3">
                <div class="s-input mr-4">
                    <input v-model="data.seekingmen" type="checkbox" name="seeking" id="men" value="men"><label
                        for="men">Men</label>
                </div>
                <div class="s-input mr-3">
                    <input v-model="data.seekingwoman" type="checkbox" name="seeking" id="woman" value="woman"><label
                        for="woman">Woman</label>
                </div>
                <div class="s-input">
                    <input v-model="data.seekingother" type="checkbox" name="seeking" id="seekingother" value="other"><label
                        for="seekingother">Other</label>
                </div>
            </div>
        </div>
        <div class="joun-button mt-4">
            <button class="custom-button" @click="joinNow()">Next</button>
        </div>
    </div>
    <Toast :data="data" ref="toast"></Toast>
</template>
<script>
import Toast from '../controls/toast.vue'
import axios from 'axios'
import { config } from '@/config';


export default {
    components: {
        Toast,
    },
    props: ['target'],
    data() {
        return {
            data: {
                post: {},
            },

        }
    },
    computed: {
        config() {
            return config;
        },
    },
    methods: {
        validform() {
            if (!this.data.gender || (!this.data.seekingother && !this.data.seekingmen && !this.data.seekingwoman)) {
                return false;
            } else {
                return true;
            }
        },
        async joinNow() {
            await axios.get(config.APP_API + '/register').then(
                (response) => {
                    let regkey = response.data.registrationguid;
                    if (this.validform()) {
                        this.data.post.gender = this.data.gender;
                        this.data.post.seekingmale = (this.data.seekingmen ? this.data.seekingmen : false);
                        this.data.post.seekingfemale = (this.data.seekingwoman ? this.data.seekingwoman : false);
                        this.data.post.seekingother = (this.data.seekingother ? this.data.seekingother : false);
                        console.log(this.data);
                        // Store the data in a local object
                        localStorage.setItem("registration", JSON.stringify(this.data.post))
                        localStorage.setItem("regkey", regkey)
                        this.$router.push('/signup/location');
                    } else {
                        this.data.title = 'User information'
                        this.data.message = 'Please fill in all of the information to complete your registration'
                        this.data.showToast = true
                        this.$refs.toast.show();
                        return false;
                    }
                }
            ).catch(
                (error) => {
                    console.log(error);
                }
            );
        },
        validateForm() {
            this.socialEnabled = this.validform()
        },
    },
    watch: {
        'data.seeking'() {
            this.validateForm();
        },
        'data.gender'() {
            this.validateForm();
        },
    },
}
</script>
<style scoped>
input,
button,
select {
    height: 40px;
}

.select {
    padding-top: 6px;
}

.title {
    position: relative;
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
    margin-right: 0px;
    margin-bottom: 8px;
    position: relative;
    top: 7px;
    width: 95px;
}


.banner-section .join-now-box .joun-button .custom-button {
    padding: 6px 45px;
}

.banner-section .join-now-box .single-option .option label {
    text-transform: none;
}

.banner-section .join-now-box .single-option .title {
    padding-bottom: 8px;
}

input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-feature-settings: inherit;
    font-variation-settings: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0.5em;
}

.banner-section .join-now-box .single-option .option label {
    font-size: 18px;
}
</style>
