<template>
    <div class="p-3 d-flex w-100 mb-3">
        <div class="big-box">
            <div class="position-relative">
                <div class="line"></div>
                <div class="box bg-white p-2 d-flex justify-content-center align-items-center">
                    1
                </div>
            </div>
            <div class="text-center">Select Plan</div>
        </div>
        <div class="big-box">
            <div class="position-relative">
                <div class="line"></div>
                <div class="box bg-white p-2 d-flex justify-content-center align-items-center">
                    2
                </div>
            </div>
            <div class="text-center">Payment Details</div>
        </div>
        <div class="big-box">
            <div class="position-relative">
                <div class="line"></div>
                <div class="box bg-white p-2 d-flex justify-content-center align-items-center">
                    3
                </div>
            </div>
            <div class="text-center">Confirm Payment</div>
        </div>
    </div>
</template>
<style scoped>
.text-center {
    font-size: 12px;
    margin-top: 5px;
}

.line {
    height: 2px;
    background: black;
    position: absolute;
    right: 50%;
    z-index: 0;
    width: 100%;
    top: 0;
    bottom: 0;
    /* left: 0px; */
    margin: auto;
}

.box {
    z-index: 1;
    position: relative;
    width: 50px;
    border: 1px solid black;
    background: white;
    background-color: white;
    margin: auto;
    border-radius: 50%;
    height: 50px;
    border: 2px solid #000;
}

.big-box {
    flex: 1;
    /*width: 25%;*/
}

.big-box:first-child .line {
    width: 0;
}

div.box {
    font-size: 22px;
    font-weight: bold;
}
</style>