<template>
    <div class="container">
        <div class="footer-links">
            <div class="row">
                <div class="col-lg-12">
                    <hr class="hr">
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="link-wrapper one">
                        <h4 class="f-l-title">
                            Our Information
                        </h4>
                        <ul class="f-solial-links">
                            <li>
                                <a href="#">
                                    <i class="fas fa-angle-double-right"></i>About Us
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fas fa-angle-double-right"></i>Contact Us
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fas fa-angle-double-right"></i>Customer Reviews
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fas fa-angle-double-right"></i>Success Stories
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fas fa-angle-double-right"></i>Business License
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="link-wrapper two">
                        <h4 class="f-l-title">
                            My Account
                        </h4>
                        <ul class="f-solial-links">
                            <li>
                                <a href="#">
                                    <i class="fas fa-angle-double-right"></i>Manage Account
                                </a>
                            </li>
                            <li>
                                <a href="/content/safety">
                                    <i class="fas fa-angle-double-right"></i>Safety Tips
                                </a>
                            </li>
                            <li>
                                <a href="/content/account-verification">
                                    <i class="fas fa-angle-double-right"></i>Account Varification
                                </a>
                            </li>
                            <li>
                                <a href="/content/safety-security">
                                    <i class="fas fa-angle-double-right"></i>Safety & Security
                                </a>
                            </li>
                            <li>
                                <a href="/content/membership-levels">
                                    <i class="fas fa-angle-double-right"></i>Membership Levels
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="link-wrapper three">
                        <h4 class="f-l-title">
                            help center
                        </h4>
                        <ul class="f-solial-links">
                            <li>
                                <a href="/content/help-centre">
                                    <i class="fas fa-angle-double-right"></i>Help centre
                                </a>
                            </li>
                            <li>
                                <a href="/content/faq">
                                    <i class="fas fa-angle-double-right"></i>FAQ
                                </a>
                            </li>
                            <li>
                                <a href="/content/quick-start">
                                    <i class="fas fa-angle-double-right"></i>Quick Start Guide
                                </a>
                            </li>
                            <li>
                                <a href="/content/tutorials">
                                    <i class="fas fa-angle-double-right"></i>Tutorials
                                </a>
                            </li>
                            <li>
                                <a href="https://blog.honeybee.rsvp" target="_blank">
                                    <i class="fas fa-angle-double-right"></i>Member Blog
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="link-wrapper four">
                        <h4 class="f-l-title">
                            legal
                        </h4>
                        <ul class="f-solial-links">
                            <li>
                                <a href="/content/privacy">
                                    <i class="fas fa-angle-double-right"></i>Privacy policy
                                </a>
                            </li>
                            <li>
                                <a href="/content/refund-policy">
                                    <i class="fas fa-angle-double-right"></i>Refund Policy
                                </a>
                            </li>
                            <li>
                                <a href="/content/refund-policy">
                                    <i class="fas fa-angle-double-right"></i>Cookie policy
                                </a>
                            </li>
                            <li>
                                <a href="/content/report-abuse">
                                    <i class="fas fa-angle-double-right"></i>Report abuse
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="copyright-wrapper">
            <div class="row">
                <div class="col-lg-12">
                    <hr class="hr2">
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 align-self-center">
                    <div class="copyr-text">
                        <span>
                            Copyright © 2024. All Rights Reserved By
                        </span>
                        <a href="#">BentoGroup</a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <ul class="footer-social-links">
                        <li>
                            <a href="#">
                                <i class="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-facebook-f"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-instagram"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-dribbble"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>