<template>
  <!-- LOGIN FORM -->
  <section class="log-reg">
    <div class="container">
      <div class="row justify-content-center">
        <div class="image image-log">
        </div>
        <div class="col-lg-7">
          <div class="log-reg-inner">
            <div class="main-content inloginp">
              Login Link
            </div>
          </div>
        </div>
      </div>
    </div>
    <Toast :data="data" ref="toast"></Toast>
  </section>
</template>
<script>
import { config } from '@/config';
import { setAuthorization, logout } from '@/utils/auth'
import axios from 'axios'

import Toast from '../controls/toast.vue'
import router from '@/routes';
export default {
  name: "LoginLinkVue",
  components: {
    Toast,
  },
  data() {
    return {
      data: {
        title: "",
        message: "",
      }
    }
  },
  mounted() {
    //Clear local session token and user
    logout();
    this.login();
  },
  methods: {
    tokenError() {
      //New account is required
      this.data.title = "Login Error";
      this.data.message = "There was an error logging in.  Please try again."
      this.$refs.toast.show();
    },
    async login() {
      const token = window.location.href.split('/').pop();
      await axios.get(config.APP_API + '/login/link/' + token).then((response) => {
        setAuthorization(response.data);
        if (this.$route.query.redirect) {
          router.push(this.$route.query.redirect);
        } else {
          router.push('/members');
        }
      }).catch((error) => {
        // this.data.message = error.response;
        console.log("Response Status: " + error);
        this.tokenError();
      })
    }
  }
}
</script>
