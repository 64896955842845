<template>
  <div class="container">
    <Toast :data="data" ref="toast"></Toast>
    <div class="row justify-content-center">
      <div class="profile-main-content">
        <div class="write-post-area">
          <div class="write-area">
            <label for="">About me</label>
            <textarea v-model="userlocal.aboutme"
              placeholder="Tell us something about you to make you stand out from the crowd..."></textarea>
          </div>
          <div class="write-area">
            <label for="">What I'm looking for</label>
            <textarea v-model="userlocal.lookingfor" placeholder="Tell us what you are looking for in a partner"></textarea>
          </div>
          <div class="mt-3">
                <DoubleRangeSlider :value="userlocal.age" @input="userlocal.age = $event.target.value" :label="'Age'" :min=18 :max=99 :unit="'years'"></DoubleRangeSlider>
          </div>
          <div class="mt-3">
                <DoubleRangeSlider :value="userlocal.height" @input="userlocal.height = $event.target.value" :label="'Height'" :min=100 :max=250 :unit="'cm'"></DoubleRangeSlider>
          </div>
          <div class="submit-area">
            <div class="right">
              <button @click="submit()" :disabled="!populated" class="custom-button">
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Toast from '../controls/toast.vue'
import DoubleRangeSlider from '../controls/DoubleRangeSlider.vue'
import { userguid, authenticationHeader } from '../../utils/auth'
import { config } from '../../config'
import axios from 'axios'

export default {
  name: "ProfileVue",
  props: ['user'],
  components: {
    Toast,
    DoubleRangeSlider
  },
  computed: {
    populated() {
      return this.userlocal.aboutme !== '' && this.userlocal.lookingfor !== ''
    }
  },
  data() {
    return {
      config: config,
      userlocal: this.user,
      data: {
        title: '',
        message: '',
      }

    }
  },
  methods: {
    submit() {
      const requestURL = config.APP_API + '/user/about-me/' + userguid()
      axios.put(requestURL, this.user, authenticationHeader()).then(() => {
        this.data.title = 'Success';
        this.data.message = 'Profile updated successfully '
        this.$refs.toast.show();
      })
        .catch((error) => {
          console.log(error)
        })
    }
  },

}
</script>
<style scoped>
.profile-section {
  padding: 95px 0px 120px;
}

.profile-section .left-profile-area .profile-uplodate-photo {
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  -webkit-box-shadow: 0px 10px 5px 0px rgba(119, 123, 146, 0.02);
  box-shadow: 0px 10px 5px 0px rgba(119, 123, 146, 0.01);
  padding: 0;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.profile-section .left-profile-area .profile-about-box .p-inner-content .profile-img {
  background: #fff;
  width: 180px;
  height: 180px;
  position: absolute;
  border-radius: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  top: -60px;
  z-index: 9;
}

.profile-section .left-profile-area .profile-about-box .p-inner-content .profile-img::before {
  position: absolute;
  content: "";
  width: 240px;
  height: 240px;
  background: rgba(255, 255, 255, 0.2);
  top: -10px;
  z-index: -1;
  left: -10px;
  border-radius: 50%;
}

.profile-section .left-profile-area .profile-about-box .p-inner-content .profile-img::after {
  position: absolute;
  content: "";
  width: 260px;
  height: 260px;
  background: rgba(255, 255, 255, 0.2);
  top: -20px;
  z-index: -1;
  left: -20px;
  border-radius: 50%;
}

.profile-section .left-profile-area .profile-about-box .p-inner-content .profile-img[data-v-1f2e67c3] {
  background: #fff;
  width: 220px;
  height: 220px;
  position: absolute;
  border-radius: 50%;
  left: 50%;
  transform: translateX(-50%);
  top: -130px;
  z-index: 9;
}

.profile-section .left-profile-area .profile-about-box .p-inner-content {
  position: relative;
  padding: 120px 30px 30px;
  text-align: center;
}

.profile-uplodate-photo {
  text-align: center;
}
</style>