<template>
  <div class="margin-top:100px;">
    this is where is should be
    <p>With more and more text</p>
    <p>With more and more text</p>
    <p>With more and more text</p>
    <p>With more and more text</p>
  </div>
  <div class="margin-top:100px;">
    Checkout is here
    <stripe-checkout ref="checkoutRef" mode="subscription" :pk="publishableKey" :line-items="lineItems"
      :success-url="successURL" :cancel-url="cancelURL" @loading="v => loading = v" />
    <button @click="submit">Purchase</button>
  </div>
</template>

<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import { config } from '@/config';

export default {
  components: {
    StripeCheckout,
  },
  data() {
    this.publishableKey = config.STRIPE.PUBLISHABLE_KEY;
    return {
      loading: false,
      lineItems: [
        {
          price: 'price_1NqKWuJxj1kvyy0v4D9cpXRB', // The id of the recurring price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      successURL: config.STRIPE.SUCCESS_URL,
      cancelURL: config.STRIPE.CANCEL_URL,
    };
  },
  methods: {
    submit() {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    },
  },
};
</script>