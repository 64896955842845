<template>
    <div class="card-content" :style="cardStyle()">
        <h4>{{ offerobject.name }}</h4>
        <div class="card-desc">
            <h3>{{ offerobject.title }}</h3>
            <h2>{{ offerobject.description }}</h2>
            <p></p>
            <div>
                <stripe-checkout ref="checkoutRef" :mode="offerobject.paymenttype" :pk="publishableKey" :line-items="offerArray"
                    :success-url="successURL" :cancel-url="cancelURL" :clientReferenceId="userguid" @loading="v => loading = v" />
                <button @click="submit" class="custom-button">Purchase</button>
            </div>
        </div>
    </div>
</template>
<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import { userguid } from '@/utils/auth';
export default {
    components: {
        StripeCheckout,
    },
    props: ['offer', 'success', 'cancel', 'pubkey', 'lineitems'],
    computed: {
        offerobject() {
            return this.offer;
        },
        offerArray() {
            var result = new Array();
            result.push({ price: this.offer.sku, quantity: 1});
            return result;
        },
        successURL() {
            return this.success;
        },
        cancelURL() {
            return this.cancel;
        },
        publishableKey() {
            return this.pubkey;
        },
        userguid() {
            return userguid();
        },
    },
    setup() {
        return {};
    },
    data() {
        return {
            sessionId: 'session-id',
        };
    },
    methods: {
        cardStyle() {
            return {
                backgroundImage: `url(${this.offerobject.image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: '400px',
                width: '300px',
                opacity: '0.8',
            }
        },
        submit() {
            this.$refs.checkoutRef.redirectToCheckout();
        },
    }
}
</script>
<style scoped>
h4 {
    padding-top: 10px;
    font-size: 24px;
    font-weight: bolder;
}

.card-content {
    background: #ffffff;
    border: 4px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
    text-align: center;
    width: 100%;
    margin-bottom: 1em;
}

.card-content h4 {
    color: #000;
    text-shadow: 1px 1px #fff;
}


.card-img img {
    width: 100%;
    height: auto;
    display: block;
}

.card-img span {
    position: absolute;
    top: 15%;
    left: 12%;
    background: #1ABC9C;
    padding: 6px;
    color: #fff;
    font-size: 12px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    transform: translate(-50%, -50%);
}

.card-desc {
    padding: 1.25rem;
    margin-top: 55%;
    color: #fff;
    text-shadow: 1px 1px #000;
}


.card-desc h3 {
    font-weight: 600;
    font-size: 1.5em;
    line-height: 1.3em;
    margin-top: 0;
    margin-bottom: 5px;
    padding: 0;
}

.card-desc p {
    font-size: 14px;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.5;
    margin: 0px;
    margin-bottom: 20px;
    padding: 0;
    font-family: 'Raleway', sans-serif;

}
</style>