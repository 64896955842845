<template>
  <!-- FORGOT PASSWORD FORM -->
  <section class="log-reg mt-3">
    <div class="container">
      <div class="row justify-content-center">
        <div class="image image-log">
        </div>
        <div class="col-lg-7">
          <div class="log-reg-inner">
            <div class="main-content inloginp">
              <div class="form-group">
                <label for="">Email Address*</label>
                <input v-model="data.email" @click="hideToast()" type="email" class="my-form-control"
                  placeholder="Enter Your Email">
              </div>
              <div class="button-wrapper">
                <button @click="submit()" type="submit" class="custom-button">Reset Password</button>
              </div>
              <Toast :data="data" ref="toast"></Toast>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Toast from '../controls/toast.vue'
import axios from 'axios'
import { config } from '@/config'


export default {
  name: "ForgotPasswordVue",
  components: {
    Toast
  },
  data() {
    return {
      data: {
        email: '',
        title: '',
        message: '',
      }
    }
  },
  methods: {

    validateForm() {
      return (this.data.email.length !== 0);
    },
    hideToast() {
      this.$refs.toast.hide();
    },
    async submit() {
      if (this.validateForm()) {
        await axios.post(config.APP_API + '/login/forgotpassword', {
          email: this.data.email
        }).then((response) => {
          console.log(response.json())
          this.data.title = 'Forgot Password';
          this.data.message = 'Password reset email has been sent to your email address';
          this.$refs.toast.show();
        }).catch((error) => {
          console.error(error.message);
          this.data.title = 'Forgot Password';
          this.data.message = 'Password reset email has been sent to your email address';
          this.$refs.toast.show();
        })
      } else {

        this.data.title = 'Forgot Password';
        this.data.message = 'Enter your email address';
        this.$refs.toast.show();
      }
    }
  }
}
</script>
<style scoped>
.log-reg {
  top: 100px;
}
</style> 