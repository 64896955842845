<template>
    <div class="container">
        <Toast :data="data" ref="toast"></Toast>
        <div class="row justify-content-center">
            <div class="profile-main-content">
                <div class="write-post-area">
                    <div class="write-area">
                        <label>Location</label>
                        <DynamicDataList :input="user.location" :savecallback="saveData.bind()" :callback="fetchDataFromDatabase.bind('')" :searchResults="searchResults">
                        </DynamicDataList>
                     </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import DynamicDataList from '../controls/DynamicDataList.vue'
import Toast from '../controls/toast.vue'
import axios from 'axios'
import _ from 'lodash'
import { config } from '../../config'
import { authenticationHeader } from '../../utils/auth'

export default {
    name: 'MyLocation',
    components: {
        DynamicDataList,
        Toast,
    },
    props: ['user'],
    data() {
        return {
            searchQuery: '',
            searchResults: [],
            userlocal: this.user,
            data:{},
        }
    },
    watch: {
        searchQuery: _.debounce(function (newQuery) {
            this.fetchDataFromDatabase(newQuery);
        }, 300),
    },
    methods: {
        fetchDataFromDatabase(search) {
            axios.post(config.APP_API + '/location', { search: search }, authenticationHeader())
                .then(response => {
                    var result = [];
                    for (var i in response.data) {
                        result.push(response.data[i].location);
                    }
                    this.searchResults = result;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        saveData(location) {
            console.log('submit ', this.user)
            const requestURL = config.APP_API + '/user/location/' + this.userlocal.guid
            axios.put(requestURL, {location: location}, authenticationHeader()).then(() => {
                this.data.title = 'Success';
                this.data.message = 'Location updated successfully '
                this.$refs.toast.show();
            })
                .catch((error) => {
                    console.log(error)
                })
        },
        populated(){
            return true
        }
    }
}

</script>