<template>
    <section>
        <PaymentSteps></PaymentSteps>
        <div class="container">
            <div class="row">
                <Offers></Offers>
                <DiscountCode></DiscountCode>
            </div>
        </div>
    </section>
</template>
  
<script>
import Offers from './Offers.vue';
import DiscountCode from './DiscountCode.vue';
import PaymentSteps from './PaymentSteps.vue';

export default {
    name: "PaymentsVue",
    components: {
        Offers,
        DiscountCode,
        PaymentSteps,
    },
    setup() {
        return {};
    }
};
</script>
  
<style scoped>
section {
    padding: 100px 0;
}

h2 {
    text-align: center;
    margin-top: 50px;
    font-size: 2em;
}
/* End card section */
</style>