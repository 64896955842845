<template>
    <div class="container">
        <Toast :data="data" ref="toast"></Toast>
        <div class="row justify-content-center">
            <div class="profile-main-content">
                <div class="write-post-area">
                    <div class="write-area">
                        <input name="userguid" v-bind:value=userguid>
                        <label for="groupname">Name</label>
                        <input id="groupname" v-model="form.name" placeholder="Name of the group">
                        <label for="groupdescription">Description</label>
                        <textarea id="groupdescription" type="text" v-model="form.description"
                            placeholder="About the group..."></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <button type="button" class="custom-button" v-on:click="save">Save</button>
                    </div>
                    <div class="col">
                        <button type="button" class="custom-button" v-on:click="cancel">Cancel</button>
                    </div>
                    <div class="col">
                        <button type="button" class="custom-button" v-on:click="remove">Remove</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Toast from '../controls/toast.vue'
import { userguid, authenticationHeader, loggedin } from '@/utils/auth';
import { config } from '@/config';
import axios from 'axios';

export default {
    name: 'EventCreateVue',
    components: {
        Toast,
    },
    props: ['user','group'],
    computed: {
        userguid: userguid,
    },
    data() {
        return {
            data:{},
            form: {},
            userlocal: this.user,
            locale: {},
            grouplocal: {
                name: "",
                description: ""
            },
        }
    },
    methods: {
        async getData() {
            this.form.name = this.grouplocal.name
            this.form.description = this.grouplocal.description
        },
        validateForm() {
            if (this.form.name == null || this.form.name == "") {
                return false;
            }
            if (this.form.description == null || this.form.description == "") {
                return false;
            }
            return true;
        },
        async postData() {
            this.form.userguid = this.userguid;
            if (this.validateForm()) {
                axios.post(config.APP_API + '/groups',
                    {
                        name: this.form.name,
                        description: this.form.description,
                        userguid: this.userguid
                    },
                    authenticationHeader
                ).then((response) => {
                    // Handle success
                    console.log(response)
                }).catch((error) => {
                    // Handle error
                    console.log(error)
                });
            }
            else {
                this.data.title = 'Group'
                this.data.message = 'Please fill in all of the information to set up a group'
                this.data.showToast = true
                this.$refs.toast.show();
                return false;
            }
        },
        save() {
            this.postData()
           // this.$router.push("/groups");
        },
        cancel() {
            this.$router.push("/groups");
        },
        remove() {
            this.$router.push("/groups");
        }
    },
    mounted() {
        if (!loggedin()) {
            this.$router.push({ name: 'Home' });
        }
        else {
            this.getData();
        }

    }

}
</script>
<style>
.preference-header2 {
    margin-top: 20px;
    margin-bottom: 20px;
}

hr {
    margin-top: 20px;
    margin-bottom: 20px;
}
</style>