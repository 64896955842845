<template>
    <div class="profile-uplodate-photo">
        <div class="p-u-p-list">
            <div v-for="image in images" :key="image.id" class="my-col" :ref="image.id">
                <MyImagePanelThumbNail :data="image">
                </MyImagePanelThumbNail>
            </div>
        </div>
    </div>
</template>
<script>
import MyImagePanelThumbNail from './MyImagePanelThumbNail.vue'
import { config } from '../../config'
import { getState } from '../../utils/globalFunctions'
import { authenticationHeader } from '../../utils/auth'
import axios from 'axios'

export default {
    name: "MyImagePanel",
    props: ['guid'],
    components: {
        MyImagePanelThumbNail,
    },
    data() {
        return {
            images: [],
            state: getState(),
        }
    },
    computed: {
        config() {
            return config
        }
    },
    mounted() {
        this.getData();
        window.addEventListener(config.events.USER_PROFILE_IMAGE_UPLOADED, () => {
            this.getData(this.state)
        })
        window.addEventListener(config.events.USER_PROFILE_IMAGE_DELETED, () => {
            this.getData(this.state)
        })
        window.addEventListener(config.events.USER_STATE_CHANGE, (event) => {
           this.getData(event.detail.state)
        });
    },
    methods: {
        async getData(state) {
            if (state == undefined) {
                state = config.PUBLIC
            }
            const requestURL = config.IMAGE_API + '/images/user/' + this.guid + (state === config.PUBLIC ? '' : '/' + state)
            console.log(requestURL)
            axios.get(requestURL, authenticationHeader())
                .then((response) => {
                    this.images = response.data

                })
                .catch((error) => {
                    console.log(error)
                })
        },
    },
}
</script>
```