<template>
    <div v-if="!enabled">Register with Facebook disabled until the form is complete</div>
    <div v-if="enabled" class="container">
        <button class="btn-facebook btn-social" @click="logInWithFacebook">
            <img src="assets/images/facebook-logo.svg" alt="f"> Sign in with Facebook
        </button>
    </div>
    <div v-if="config.DEBUG">
        {{ data }}<br/>
    </div>
</template>
<script>
import { config } from '@/config';
import axios from 'axios';

export default {
    name: "facebookLogin",
    props: ['data','enabled'],
    computed: {
        config() {
            return config;
        }
    },
    methods: {
        async logInWithFacebook() {
            await this.loadFacebookSDK(document, "script", "facebook-jssdk");
            await this.initFacebook();
            window.FB.login(function (response) {
                if (response.authResponse) {
                    // Add the user and login
                    axios.post(config.APP_API + '/login/facebook', {
                        token: response.authResponse.userID,
                        gender: this.data.gender,
                        seeking: this.data.seeking,
                        agefrom: this.data.agefrom,
                        ageto: this.data.ageto,
                        country: this.data.country
                    }).then(function (response) {
                        if (response.data.success) {
                            console.log(response.data);
                        }
                    });
                } else {
                    alert("User cancelled login or did not fully authorize.");
                }
            });
            return false;
        },
        async initFacebook() {
            window.fbAsyncInit = function () {
                window.FB.init({
                    appId: config.FACEBOOK_APP_ID, //You will need to change this
                    cookie: true, // This is important, it's not enabled by default
                    version: "v16.0"
                });
            };
        },
        async loadFacebookSDK(d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }
    },
    mounted() {
        this.loadFacebookSDK(document, "script", "facebook-jssdk");
        this.initFacebook();

    }
};
</script>
<style>
.btn-facebook {
    color: #fff;
    background-color: #4267B2;
    border-color: rgba(0, 0, 0, 0.2);
    width: 177px;
    height: 40px;
    border-radius: 2px;
}

.btn-social {
    position: relative;
    padding-left: 30px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    font-size: 14px;
    text-overflow: ellipsis;
}

.btn-social:hover {
    color: #eee;
}

.btn-social :first-child {
    position: absolute;
    left: -7px;
    top: 2px;
    height: 29px;
    bottom: 0;
    width: 40px;
    /*padding: 7px;*/
    text-align: center;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
}</style>