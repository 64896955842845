<template>
    <div class="container" style="margin-top: 100px;">
        <h1>{{ title }}</h1>
        <p>{{ message }}</p>
        <div @click="goBack()" class="btn-back">Back</div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import * as amplitude from '@amplitude/analytics-browser';
import { userguid } from '@/utils/auth';
import { config } from '../../config';
import { loggedin } from '../../utils/auth';

export default defineComponent({
    name: 'VideoUpload',
    props: ['title', 'message', 'eventname'],
    methods: {

        goBack() {
            this.$router.go(-1);
        }
    },
    mounted() {
        const route = useRoute();
        const amplitudeAPIKey = config.amplitude.API_KEY;
        amplitude.init(amplitudeAPIKey);
        amplitude.track(this.eventname, {
            route: route.path,
            userguid: (loggedin() ? userguid() : ''),
        });
    }
});
</script>
<style scoped>
.btn-back {
    text-decoration: underline;
    cursor: pointer;
}
</style>
```
