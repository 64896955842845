<template>
    <input v-model="searchQuery" type="text" placeholder="Type here..."/>
    <div class="form-group">
        <select v-show="searchResults.length > 0" :size="searchResults.length" v-model="searchQuery" @change="save()"
            :key="searchResults.length" class="select-bar" style="height: auto; overflow: visible; display: none;">
            <option v-for="result in searchResults" :key="result" :value="result">{{ result }}</option>
        </select>
    </div>
</template>
<script>
import _ from 'lodash';

export default {
    name: 'DynamicDataLoadList',
    props: ['callback', 'searchResults','input','savecallback'],
    data() {
        return {
            searchQuery: this.input,
        }
    },
    watch: {
        searchQuery: _.debounce(function (newQuery) {
            this.fetchDataFromDatabase(newQuery);
        }, 300),
    },
    methods: {
        fetchDataFromDatabase(search) {
            this.callback(search)
        },
        save() {
         this.savecallback(this.searchQuery)
        }
    },
}
</script>