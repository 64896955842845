<template>
    <Toast :data="data" :title="Debug" ref="debug">
    </Toast>
</template>
<script>
import { loggedin } from '@/utils/auth';
import { isMobile } from '@/utils/globalFunctions';
import { config } from '@/config';
import Toast from './toast.vue'

export default {
    name: 'DebugVue',
    data(){
        return {
            data: {
                showToast: config.DEBUG,
                title: 'Debug',
                message: {
                    isMobile: isMobile(),
                    loggedin: loggedin(),
                }
            }
        }
    },
    components:{
        Toast,
    },
    methods: {
        loggedin() {
            return loggedin;
        },
        isMobile() {
            return isMobile;
        },
        config(){
            return config;
        }
    },
    mounted() {
        this.$refs.debug.show;
    }
}
</script>