<template>
    <TopBanner v-if="!isMobile"></TopBanner>
    <!-- ==========Header-Section========== -->

    <header class="header header-section" :style="data.headerBackground">
        <div class="container">
            <div class="header-wrapper">
                <div class="logo">
                    <a href="/home">
                        <img v-if="data.loggedInState" src="/assets/images/logo/honeybee-logo-glyph-icon.png" alt="Honey Bee RSVP">
                        <img v-if="!data.loggedInState" src="/assets/images/logo/honeybee-logo-glyph.png" alt="Honey Bee RSVP">
                    </a>
                </div>
                 <ul ref="menu" class="menu">
                    <li v-if="data.loggedInState && config.FEATURES.NaughtyMode">
                        <div v-if="data.naughtyMode" class="nav-text">Naughty Mode On</div>
                        <div v-if="!data.naughtyMode" class="nav-text">Naughty Mode Off</div>
                        <a @click="toggleState" class="state-toggle">
                            <i class="fa-solid fa-face-smile-wink"></i>
                        </a>
                    </li>
                    <li v-if="data.loggedInState" class="nav-item">
                        <MenuItemIcon icon="search" itemname="Search" path="/profile/preferences" :navstate="navstate">
                        </MenuItemIcon>
                    </li>
                    <li v-if="data.loggedInState" class="nav-item active">
                        <MenuItemIcon icon="chat" itemname="Connections" path="/connections" :navstate="navstate">
                        </MenuItemIcon>
                    </li>
                    <li v-if="config.FEATURES.Groups" class="nav-item">
                        <MenuItemIcon icon="account_circle" itemname="Groups" path="/groups" :navstate="navstate">
                        </MenuItemIcon>
                    </li>
                    <li v-if="config.FEATURES.Events" class="nav-item">
                        <MenuItemIcon icon="account_circle" itemname="Events" path="/events" :navstate="navstate">
                        </MenuItemIcon>
                    </li>
                    <li v-show="data.loggedInState" class="nav-item">
                        <MenuItemIcon icon="account_circle" itemname="Members" path="/members" :navstate="navstate">
                        </MenuItemIcon>
                    </li>
                    <li v-if="data.loggedInState && config.FEATURES.Settings" class="nav-item">
                        <MenuItemIcon icon="settings" itemname="Settings" path="/settings" :navstate="navstate">
                        </MenuItemIcon>
                    </li>
                    <li v-if="data.loggedInState" class="nav-item">
                        <MenuItemIcon icon="logout" itemname="Logout" path="/home" :navstate="navstate" :method="logout">
                        </MenuItemIcon>
                    </li>
                    <li v-show="!data.loggedInState" class="nav-item">
                        <MenuItemIcon icon="account_circle" itemname="Login" path="/login" :navstate="navstate">
                        </MenuItemIcon>
                    </li>
                    <li v-show="data.loggedInState" class="profile">
                        <img :src="returnImageString()" class="icon" v-on:click="gotoProfile()">
                    </li>
                </ul>
                <div ref="headerbar" class="header-bar d-lg-none">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    </header>
    <!-- ==========Header-Section========== -->
</template>
<script>

import TopBanner from '../TopBanner.vue'
import MenuItemIcon from './MenuItemIcon.vue'
import { isMobile, getState, toggleState, restrictedState } from '@/utils/globalFunctions'
import { config } from '../../config'
import { loggedin, logout, userguid } from '@/utils/auth'

export default {
    name: 'NavBarVue',
    components: {
        TopBanner,
        MenuItemIcon,
    },
    computed: {
        config() {
            return config;
        },
        navstate() {
            return this.data.state;
        },
        userguid() {
            return userguid();
        }
    },
    data() {
        return {
            data: {
                showHeader: true,
                lastScrollPosition: 0,
                scrollOffset: 40,
                loggedInState: false,
                state: getState(),
                headerBackground: {},
                naughtyMode: restrictedState(),
            }
        }
    },
    mounted() {
        this.lastScrollPosition = window.pageYOffset
        window.addEventListener('scroll', this.onScroll)

        window.addEventListener(config.events.LOGGED_IN_STATUS_CHANGE, (event) => {
            this.data.loggedInState = event.detail.loggedin;
        });

        window.addEventListener(config.events.USER_STATE_CHANGE, (event) => {
            this.data.state = event.detail.state;
            if (this.data.state === config.PUBLIC) {
                this.data.headerBackground = { backgroundColor: '#d39400' }
                this.data.naughtyMode = false;
            } else if (this.data.state === config.RESTRICTED) {
                this.data.headerBackground = { backgroundColor: 'black' }
                this.data.naughtyMode = true;
            }
        });

        window.addEventListener(config.events.MENU_CLOSE, () => {
            try {
                this.$refs.menu.classList.remove('active');
                this.$refs.headerbar.classList.remove('active');
            } catch (e) {
                console.log(e);
            }
        });

        this.data.loggedInState = loggedin();


        if (this.data.state === config.PUBLIC) {
            this.data.headerBackground = { backgroundColor: '#d39400' }
            this.data.naughtyMode = false;
        } else if (this.data.state === config.RESTRICTED) {
            this.data.headerBackground = { backgroundColor: 'black' }
            this.data.naughtyMode = true;
        } else {
            this.data.headerBackground = { backgroundColor: '#d39400' }
            this.data.naughtyMode = false;
        }

    },
    beforeunmount() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        isMobile,
        // Toggle if navigation is shown or hidden
        onScroll() {
            if (window.pageYOffset < 0) {
                return
            }
            if (Math.abs(window.pageYOffset - this.lastScrollPosition) < this.scrollOffset) {
                return
            }
            this.showHeader = window.pageYOffset < this.lastScrollPosition
            this.lastScrollPosition = window.pageYOffset
        },
        logout() {
            logout();
            this.$router.push('/home');
        },
        toggleState() {
            toggleState();
        },
        gotoProfile(){
            this.$router.push('/profile');
        },
        returnImageString() {
            return config.IMAGE_API + '/images/user/' + this.userguid + '/index/1';
        },
    }
}
</script>
<style scoped>
.header {
    transform: translateY(0);
    transition: transform 300ms linear;
}

.header.is-hidden {
    transform: translateY(-100%);
}

.menu {
    margin: 1px 0 0 0;
}

a.nav-link:hover,
a.nav-link:visited {
    color: #fff;
}

a.state-toggle i {
    color: #fff;
    font-size: 28px;
}

.nav-text {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    display: inline;
    position: relative;
    top: -5px;
}

.logo a img {
    height: 53px;
}

.profile .icon {
    width: 45px;
    border-radius: 50%;
}


</style>
