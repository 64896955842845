<template>
  <div>
    <div v-show="imageSrc">
      <img :src="imageSrc" alt="Image preview" ref="img" :style="{ height: '300px' }" />
    </div>
    <div class="button-wrapper mt-3 text-center">
      <button v-if="!imageSrc" @click="imageInput.click()" class="custom-button">Add image</button>
      <button v-if="imageSrc" @click=" handleImageCropped " class="custom-button mr-3">Upload</button>
      <button v-if=" imageSrc " @click=" fileCleared " class="custom-button">Clear</button>
    </div>
    <input type="file" ref="imageInput" accept=".jpg,.jpeg,.png" @change=" fileChanged " style="display: none" />
  </div>
</template>
<script>

import { ref, watchEffect, onMounted, onUnmounted, watch } from 'vue'
import { authenticationHeaderMultiPart } from '@/utils/auth'
import { config } from '../../config'
import axios from 'axios'
import Cropper from 'cropperjs'
import { userguid } from '@/utils/auth'
import { getState } from '@/utils/globalFunctions'

export default {
  name: "ImageUploaderVue",
  emits: ['imageCropped', 'state'],
  components: {

  },
  data() {
    return {
      imgList: {},
      config: config,
      state: getState(),
    }
  },
  methods: {
    inputImg() {

    }
  },
  setup(_props, { emit }) {
    const uploadEvent = new CustomEvent(config.events.USER_PROFILE_IMAGE_UPLOADED, { detail: { userguid: userguid() } });

    const imageInput = ref(null);
    const selectedFile = ref(null);
    const imageSrc = ref(null);
    const img = ref(null);
    const fileReader = new FileReader();
    let cropper = null;

    fileReader.onload = (event) => {
      imageSrc.value = event.target.result;
    };

    const handleImageCropped = () => {
      cropper
        .getCroppedCanvas({
          width: 256,
          height: 256,
        })
        .toBlob((blob) => {
          console.log(blob);
          handleImageSumitted(blob)
          emit('imageCropped', blob);
        }, 'image/jpeg');

      selectedFile.value = null;
    }

    const handleImageSumitted = async (imageBlob) => {
      const state = getState();
      const formData = new FormData();
      //formData.append('image', selectedFile.value, selectedFile.value.name);
      formData.append('image', imageBlob);
      formData.append('userguid', userguid());
      const postURL = (state === config.PUBLIC ? config.IMAGE_API : config.IMAGE_API + '/restricted');
      const response = await axios.post(postURL, formData, {
        authenticationHeaderMultiPart
      }).then((response) => {
        console.log(response);
        window.dispatchEvent(uploadEvent);
      }).catch((error) => {
        console.log(error);
      });
      console.log(response);
    }

    const fileChanged = (e) => {
      const files = e.target.files || e.dataTransfer.files;
      if (files.length) {
        selectedFile.value = files[0];
      }
    }
    const fileCleared = () => {
      selectedFile.value = null;
    }



    onMounted(() => {
      cropper = new Cropper(img.value, {
        aspectRatio: 1,
        minCropBoxWidth: 256,
        minCropBoxHeight: 256,
        viewMode: 3,
        dragMode: 'move',
        cropBoxMovable: false,
        cropBoxResizable: false,
      });

      // Listen for user state change
      window.addEventListener(config.events.USER_STATE_CHANGED, (e) => {
        console.log('User state changed');
        console.log(e);
        const state = getState();
        console.log(state);
        emit('state', state);
      });
    })

    onUnmounted(() => {
      cropper.destroy();
    })

    watchEffect(() => {
      if (selectedFile.value) {
        fileReader.readAsDataURL(selectedFile.value)
      } else {
        imageSrc.value = null;
      }
    })

    watch(imageSrc, () => {
      if (imageSrc.value) {
        cropper.replace(imageSrc.value);
      }
    }, {
      flush: 'post', // watch runs after component update
    })

    return {
      imageInput,
      selectedFile,
      fileChanged,
      fileCleared,
      imageSrc,
      img,
      handleImageCropped,
      handleImageSumitted,
    }
  },

}
</script>
<style scoped>
.cropper-view-box,
.cropper-face {
  border: 1px solid #ccc;
  border-radius: 50%;
}

.custom-button {
  background-image: -o-linear-gradient(284deg, rgb(211, 148, 0) 0%, rgb(211, 148, 0) 100%);
  background-image: linear-gradient(166deg, rgb(211, 148, 0) 0%, rgb(211, 148, 0) 100%);
  -webkit-box-shadow: 0.872px 9.962px 20px 0px rgba(12, 78, 165, 0.3);
  box-shadow: 0.872px 9.962px 20px 0px rgba(12, 78, 165, 0.3);
  padding: 11px 35px;
  font-weight: 600;
  color: #ffffff;
  border-radius: 50px;
  display: inline-block;
  border: 0px;
  cursor: pointer;
  width: auto;
  height: auto;
}

.custom-button-restricted {
  background-image: -o-linear-gradient(284deg, rgb(15, 2, 7) 0%, rgb(42, 10, 63) 100%);
  background-image: linear-gradient(166deg, rgb(14, 2, 6) 0%, rgb(47, 15, 68) 100%);
  -webkit-box-shadow: 0.872px 9.962px 20px 0px rgba(12, 78, 165, 0.3);
  box-shadow: 0.872px 9.962px 20px 0px rgba(12, 78, 165, 0.3);
  padding: 11px 35px;
  font-weight: 600;
  color: #ffffff;
  border-radius: 50px;
  display: inline-block;
  border: 0px;
  cursor: pointer;
  width: auto;
  height: auto;
}

</style>
```