<template>
    <!-- Reciever Message-->
    <div class="media w-75 mb-3">
        <img :src="userImage()" alt="user" width="40" class="rounded-circle" />
        <div class="media-body ml-3">
            <div class="bg-light rounded py-2 px-3 mb-2">
                <p class="text-small mb-0 text-muted">{{ message.message }}</p>
            </div>
            <p class="small text-muted">{{ datetime }}</p>
        </div>
    </div>
</template>
<script>
import { config } from "../../config.js";

export default {
    name: "MessageReceiver",
    props:[
        'datetime',
        'message',
    ],
    methods:{
        userImage(){
            return config.IMAGE_API + '/images/user/' + this.message.fromuserid + '/index/1';
        },
    }
}
</script>
<style></style>