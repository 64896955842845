<template>
    <div class="container">
        <EventCard :event="this.event"></EventCard>
<ul>
    <li v-for="event in events" :key="event.id">
        <router-link :to="{ name: 'EventDetails', params: { id: event.id } }">
            {{ event.title }}
        </router-link>
    </li>
</ul>
    </div>
</template>
<script>
import EventCard from './EventCard.vue'
import { userguid, authenticationHeader, loggedin } from '@/utils/auth';
import { config } from '@/config';
import axios from 'axios';

export default {
    name: 'EventListVue',
    components: {
        EventCard
    },
  data() {
    return {
      config: config,
      user: {},
      event: {
        title: 'This is an event',
        description: 'Best event ever',
        date: '01.02.2024',
        time: '8:30 AM',
        location: '',
        price: '',
        image: '',
        userguid: '21c3a642-339d-4da5-9570-e771504410bc',
      },
    }
  },
  setup() {

    return {
      userguid: userguid(),
    }
  },
  methods: {
    currentRouteName() {
      return this.$route.name;
    },
    getData(){

    },
    todaySearch() {
        this.$router.push("/events/today");
    },
    tomorrowSearch() {
        this.$router.push("/events/tomorrow");
    },  
    nextweekendSearch() {
        this.$router.push("/events/nextweekend");
    },
    choosedateSearch() {
        this.$router.push("/events/choosedate");
    },
    upcomingSearch() {
        this.$router.push("/events/upcoming");
    },
    createEvent() {
        this.$router.push("/events/create");
    },
  },
  created() {
    axios.get(config.APP_API + '/user/profile/' + this.userguid,
      authenticationHeader())
      .then((response) => {
        this.user = response.data[0];
      })
      .catch((error) => {
        console.log(error)
      })
  },
    mounted() {
        if (!loggedin()) {
            this.$router.push({ name: 'Home' });
        }
        else {
            this.getData();
        }

    }

}
</script>
<style>
.preference-header2 {
    margin-top: 20px;
    margin-bottom: 20px;
}

hr {
    margin-top: 20px;
    margin-bottom: 20px;
}
</style>