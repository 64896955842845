<template>
  <div class="container py-5 px-4" style="margin-top: 60px;">
    <div class="row rounded-lg overflow-hidden shadow">
      <ProfileMini :fromuser="this.fromuser"></ProfileMini>
      <!-- Chat Box-->
      <div class="px-3 py-3" v-for="message in messages" :key="message.id">
        <ReceiverMessage v-if:="message.receivermessage" :user=message.fromuserid :datetime=chatDateTime(message.created)
          :message=message.message>
        </ReceiverMessage>
        <SenderMessage :user=message.fromuserid :datetime=chatDateTime(message.created) :message=message.message>
        </SenderMessage>
      </div>

      <!-- Typing area -->
      <form action="#" class="bg-light">
        <div class="input-group">
          <input type="text" placeholder="Type a message" aria-describedby="button-addon2"
            class="form-control rounded-0 border-0 py-4 bg-light">
          <div class="input-group-append">
            <button id="button-addon2" type="submit" class="btn btn-link"> <i class="fa fa-paper-plane"></i></button>
          </div>
        </div>
      </form>

    </div>
  </div>
</template>
<script>
import ReceiverMessage from './MessageReceiver.vue';
import SenderMessage from './MessageSender.vue';
import ProfileMini from '../profile/ProfileMini.vue';
import axios from 'axios';
import { chatDateTime } from '@/utils/globalFunctions'
import { userguid, authenticationHeader } from '@/utils/auth'
import { config } from '@/config.js'

export default {
  name: 'SignUpVue',
  components: {
    ReceiverMessage,
    SenderMessage,
    ProfileMini
  },
  props: {
    fromuser: String,
  },
  data() {
    return {
      userguid: localStorage.userguid,
      messages: [],
    }
  },
  methods: {
    async getData() {
      axios.get(config.APP_API + '/messages/from/' + userguid() + '/to/' + this.fromuser, authenticationHeader())
        .then((response) => {
          const messages = response.data;
          messages.forEach(this.ReceiverMessage)
          this.messages = messages
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    chatDateTime,
    ReceiverMessage(message) {
      message.receivermessage = (this.userguid == message.fromuserid)
    },
  },
  computed: {
    /*fromuser() {
      return this.$route.query.fromuser
    },*/
  },
  mounted() {
    this.getData();
  }

}

</script>
<style>
.text-small {
  font-size: 0.9rem;
}

.messages-box,
.chat-box {
  overflow-y: scroll;
}

.rounded-lg {
  border-radius: 0.5rem;
}

input::placeholder {
  font-size: 0.9rem;
  color: #999;
}
</style>