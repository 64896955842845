<template>
    <CardContainer></CardContainer>
</template>
<script>
import CardContainer from './cards/CardsContainer.vue'
import { loggedin } from '@/utils/auth';
import { config } from '../config';
import axios from 'axios';
import { userguid, authenticationHeader } from '../utils/auth';

export default
    {
        name: 'MembersVue',
        components: {
            CardContainer
        },
        setup() {
            //Run a quick search to pick up an new members since the last time the user accessed this page
            axios.post(config.APP_API + '/search/' + userguid(), authenticationHeader())
                .then((response) => {
                    console.log("Find new members: ", response);
                })
                .catch((error) => {
                    console.log(error);

                });
        },
        mounted() {
            if (!loggedin()) {
                this.$router.push('/home');
            }
        }
    }
</script>
<style></style>