<template>
    <div class="join-now-box wow fadeInUp">
        <div class="single-option ">
            <div class="log-reg-inner">
                <div class="main-content inloginp">
                    <p class="title mb-2">Username*</p>
                    <div class="s-input">
                        <input ref="username" type="text" class="my-form-control" placeholder="Username...">
                    </div>
                </div>
                <div class="main-content inloginp">
                    <div class="s-input">
                        <p class="title mb-2">Email*</p>
                        <input ref="email" type="email" class="my-form-control" placeholder="Email..">
                    </div>
                </div>
            </div>
        </div>
        <div class="joun-button mt-4" style="float: left;margin: 8%;">
            <button class="custom-button" @click="back()">Back</button>
        </div>
        <div class="joun-button mt-4">
            <button class="custom-button" @click="joinNow()">Next</button>
        </div>
    </div>
    <Toast :data="data" ref="toast"></Toast>
</template>
<script>
import Toast from '../controls/toast.vue'
import axios from 'axios'
import { config } from '@/config';
import { authenticationHeader, setAuthorization } from '@/utils/auth';


export default {
    components: {
        Toast,
    },
    props: ['target'],
    data() {
        return {
            data: {
                post: {},
            },

        }
    },
    computed: {
        config() {
            return config;
        },
    },
    methods: {
        validform() {
            if (!this.$refs.username.value || !this.$refs.email.value) {
                return false;
            } else {
                if (!this.validateEmail()) {
                    return false;
                }
                else {
                    return true;
                }
            }
        },
        back() {
            this.$router.push('/signup/seekingage')
        },
        async joinNow() {
            if (this.validform()) {
                this.data.post.username = this.$refs.username.value;
                this.data.post.email = this.$refs.email.value;
                let registration = JSON.parse(localStorage.getItem("registration"))
                registration.username = this.data.post.username;
                registration.email = this.data.post.email;
                localStorage.setItem("registration", JSON.stringify(registration))
                // Send the regisation object to the API to create the registration
                // and move to the profile picture upload page
                await axios.post(config.APP_API + '/register', registration, authenticationHeader())
                    .then((response) => {
                        console.log(response.data);
                        const session = response.data;
                        setAuthorization(session);
                        //Renove registration from local storage
                        localStorage.removeItem("registration");

                        this.$router.push('/signup/profileimage');
                    })
                    .catch((error) => { console.log(error) })
            } else {
                this.data.title = 'User information'
                this.data.message = 'Please fill in all of the information to complete your registration'
                this.data.showToast = true
                this.$refs.toast.show();
                return false;
            }
        },
        validateEmail() {
            let regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
            if (regex.test(this.$refs.email.value)) {
                return true;
            } else {
                return false;
            }
        },
    },
    watch: {
        'data.seeking'() {
            this.validateForm();
        },
        'data.gender'() {
            this.validateForm();
        },
    },
}
</script>
<style scoped>
input,
button,
select {
    height: 40px;
}

.select {
    padding-top: 6px;
}

.title {
    position: relative;
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
    margin-right: 0px;
    margin-bottom: 8px;
    position: relative;
    top: 7px;
    width: 95px;
}


.banner-section .join-now-box .joun-button .custom-button {
    padding: 6px 45px;
}

.banner-section .join-now-box .single-option .option label {
    text-transform: none;
}

.banner-section .join-now-box .single-option .title {
    padding-bottom: 8px;
}

input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-feature-settings: inherit;
    font-variation-settings: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0.5em;
}

.banner-section .join-now-box .single-option .option label {
    font-size: 18px;
}
</style>
