<template>
    <div class="mini-profile d-flex align-items-center w-100 my-3">
        <div class="image mr-3">
            <img :src="returnImageString" class="round" width="100">
        </div>
        <div class="w-100">
            <h4 class="mb-0 mt-0">{{ member.username }}</h4>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import { chatDateTime } from '@/utils/globalFunctions';
import { config } from '../../config';
import { authenticationHeader } from '@/utils/auth';

// import { useRoute, useRouter } from 'vue-router'
export default {
    name: "ProfileMiniVue",
    data() {
        return {
            member: {}
        }
    },
    props:
        ['fromuser'],
    methods: {
        async getData() {
            axios.get( config.APP_API + '/member/' + this.fromuser, authenticationHeader())
                .then((response) => {
                    const member = response.data;
                    this.member = member[0]
                    this.member.lastlogin = (this.chatDateTime(this.member.lastlogin))
                    console.log(response.data)
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        chatDateTime,
    },
    mounted() {
        this.getData();
    },
    computed: {
        returnImageString() {

            // return 'url(' + config.APP_API + '/images/' + this.targetuserguid + '/1)';
            return config.IMAGE_API + '/images/user/' + this.member.guid + '/index/1';

        },
    }
}
</script>
<style>
.mini-profile h4{
    font-size: 1.5rem;
    font-weight: 400;
    color: #000;
    text-transform: capitalize;
}
.card {
    width: 400px;
    border: none;
    border-radius: 10px;
    background-color: #fff;
}

.stats {
    background: #f2f5f8 !important;
    color: #000 !important;
}

.articles,
.followers,
.rating {
    font-size: 24px;
    color: #353535;
}

.number1,
.number2,
.number3 {
    font-weight: 500;
    color: #000 !important;
}

.round {
    border-radius: 50% !important;
}
</style>